import React from "react";
// import { Meteors } from "./Meteors";
// bg-gradient-to-r from-blue-500 to-teal-500
export function MeteorsDemo({ title, description }) {
  return (
    <div className="">
      <div className="w-full relative max-w-xs group">
        <div className="absolute inset-0 h-full w-full  transform scale-[0.80] blur-2xl rounded-full transition-all duration-300 ease-in-out group-hover:scale-[0.90] group-hover:blur-3xl"></div>
        <div  className="relative bg-gradient-to-bl to-[#FF3D3D1A]  via-[#5D58B91A] from-[#5D58B91A] hover:to-[#FF3D3D66] hover:via-[#5D58B966] hover:from-[#5D58B966] shadow-black rounded-2xl">
        <div className=" px-4 py-8 h-80 overflow-hidden rounded-2xl flex flex-col items-start">

          <div className="h-5 w-5 rounded-full border flex items-center justify-center mb-4 border-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-2 w-2 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
              />
            </svg>
          </div>
          <h1 className="font-medium text-xl text-white mb-4 relative font-poppins">
            {title}
          </h1>
          <p className="font-light text-base text-white mb-4 relative font-poppins">
            {description}
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}
