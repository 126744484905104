import img from "../assets/Achivement Award 2.svg"
import img1 from "../assets/Achivement1.svg"

const Achievements = () => {
    return (
        <div className="">
            <div className="relative -z-1">
                <div className="absolute top-56 right-0 w-[400px] h-[400px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-30 blur-[120px]"></div>
            </div>
            <p className="text-white font-normal text-[32px] leading-[4rem] tracking-wide sm:text-5xl mt-8 sm:mt-16 mb-6 text-center font-bebas"> OUR {" "}
                <span className="gradient_text">ACHIEVEMENTS</span></p>
            <div className="sm:flex text-white p-4 sm:p-10">
                <div className="sm:w-[60%] text-[14px] sm:px-10 sm:text-xl leading-[30px] tracking-tight opacity-80 text-[#F5E1FF] ">
                    <p className="pb-6 font-poppins text-justify font-light">Talentverse was born out of the idea that many talented individuals in the artist, and creator industry struggle to showcase their skills, and get the exposure they deserve. Our founder, Naveen, saw the need for a Digital Talent Ecosystem that could connect these talents with opportunities to grow, learn, and monetize their skills, while giving users more control over what they see, and offering rewards in return. </p>
                    <p className="font-poppins text-justify font-light">It's been an exciting journey so far, and we're thrilled to have been recognized for our innovative, and impactful idea by the Spotlight Achievement Award for the Best Innovative Idea of the Year 2023 in the field of Social Platform at the Global Business Line Summit 2023.</p>
                </div>
                <div className="sm:px-5">
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 justify-around">
                        <img src={img} alt="" className="h-full w-full sm:w-[280px] rounded-xl" />
                        <img src={img1} alt="" className="h-full w-full sm:w-[280px] rounded-xl" />  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Achievements