import { SlSocialYoutube } from "react-icons/sl";
import { BsTwitterX, BsGooglePlay } from "react-icons/bs";
import { CiLinkedin, CiMail } from "react-icons/ci";
import { IoCallOutline, IoLogoInstagram } from "react-icons/io5";
import { PiFacebookLogo } from "react-icons/pi";
import { FaApple } from "react-icons/fa";
import img from "../assets/Logo Center.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="overflow-hidden">
            <div className="relative opacity-40 flex flex-col items-center">
                <div className="absolute w-[600px] h-[70px] bg-[#25008F] rounded-3xl mix-blend-luminosity filter blur-3xl"></div>
            </div>
            <div className="relative opacity-40 flex justify-center">
                <div className="absolute w-[600px] h-[70px] top-[620px] sm:top-[350px] bg-[#25008F] rounded-full mix-blend-soft-light filter blur-3xl"></div>
            </div>
            <div className="border-t border-[#D76D5E99] text-white my-auto flex flex-col md:flex-row justify-evenly p-6 md:p-10 font-poppins font-extralight">

                <div className="flex flex-col items-center">
                    <div className="flex gap-2 mb-4">
                        <img src={img} alt="Logo" className="w-[150px] md:w-[170px]" />
                    </div>
                    <div className="flex gap-2 p-2 h-[31px]">
                        <a
                            href="https://instagram.com/talentverse_hq"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                        >
                            <IoLogoInstagram className="mt-0.5 text-xl h-[31px] w-[31px]" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/talentversehq/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                        >
                            <CiLinkedin className="mt-0.5 text-xl h-[31px] w-[31px]" />
                        </a>
                        <a
                            href="https://www.facebook.com/Talentverse.hq"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                        >
                            <PiFacebookLogo className="mt-0.5 text-xl h-[31px] w-[31px]" />
                        </a>
                        <a
                            href="https://x.com/talentverse_hq"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                        >
                            <BsTwitterX className="p-1 mt-0.5 text-xl h-[31px] w-[31px]" />
                        </a>
                        <a
                            href="https://youtube.com/@talentverse_hq"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                        >
                            <SlSocialYoutube className="mt-0.5 text-xl h-[31px] w-[31px]" />
                        </a>
                    </div>
                </div>

                <div className="hidden sm:block mt-6 md:mt-0">
                    <p className="text-lg font-medium p-2 text-center md:text-left">Scan the QR to download our App</p>
                    <div className="flex flex-wrap justify-center md:justify-start gap-6">
                        <div className="flex flex-col items-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/images%2FIOS.png?alt=media&token=3e3ca887-6a27-46d1-aa6f-72654925575a" alt="QR Code" className="w-[100px] md:w-[120px]" />
                            <div className="bg-[#0F152E] rounded-md p-2 flex m-2">
                                <FaApple className="h-[28px] w-[28px]" />
                                <div>
                                    <p className="text-[10px]">Download on the</p>
                                    <p className="text-[12px] font-medium">App Store</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/images%2FAndoird.png?alt=media&token=d45a5e7b-5f7c-44f1-80b3-bd87caad573c" alt="QR Code" className="w-[100px] md:w-[120px]" />
                            <div className="bg-[#0F152E] rounded-md p-2 flex m-2">
                                <BsGooglePlay className="h-[28px] w-[28px]" />
                                <div>
                                    <p className="text-[10px]">Download on the</p>
                                    <p className="text-[12px] font-medium">Google Play</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 md:mt-0 flex flex-col items-center sm:items-start">
                    <p className="text-lg font-normal p-2">Navigation</p>
                    <ul className="font-extralight text-sm flex flex-col items-center sm:items-start">
                        <li className="p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/")}>Home</li>
                        <li className="p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/about")}>About us</li>
                        <li className="p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/services")}>Services</li>
                        <li className="p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/features")}>Platform</li>
                        <li className="p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/blogs")}>Blogs and Media</li>
                    </ul>
                </div>

                <div className="mt-6 md:mt-0 flex flex-col items-center sm:items-start">
                    <p className="text-lg font-medium p-2">Contact Us</p>
                    <div className="flex gap-1 p-2">
                        <p className="p-0.5 font-extralight text-sm sm:text-xl"><IoCallOutline /></p>
                        <a href="tel:+918618896112" className="font-extralight text-sm sm:font-light text-white hover:gradient_text">
                            (+91) 8618896112
                        </a>
                    </div>
                    <div className="flex gap-1 p-2">
                        <p className="p-0.5 text-xl"><CiMail /></p>
                        <a href="mailto:hello@talentverse.in" className="font-extralight text-sm sm:font-light text-white hover:gradient_text">
                            hello@talentverse.in
                        </a>
                    </div>
                    <p className="mt-4 text-lg font-medium p-2">Legal</p>
                    <p className="font-extralight text-sm sm:font-light p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/terms_and_condiitons")}>Terms and Conditions</p>
                    <p className="font-extralight text-sm sm:font-light p-2 hover:cursor-pointer hover:gradient_text" onClick={() => navigate("/privecy-policy")}>Privacy Policy</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
