// import { MacbookScrollDemo } from "./MacbookScrollDemo"
import img from "../assets/Latest Image.svg"
const FeaturesHeader = () => {
    return (
        <div className="flex flex-col items-center justify-center mt-32">
            <div className=" text-center text-white ">
                <p className="leading-[4rem] tracking-wide text-white text-[32px] sm:text-5xl font-medium font-bebas">PLATFORM {" "}  <span className="gradient_text">
                        BENEFITS</span></p>
                <p className="text-[14px] sm:text-base text-white max-w-4xl text-center p-2 font-extralight font-['Poppins']">Talentverse offers all-in-one support to help you showcase your skills, connect with opportunities, and grow as an artist.</p>
            </div>
            <img src={img} alt="" className="my-4"/>

        </div>
    )
}

export default FeaturesHeader