import React, { useState, useEffect } from "react";
import AnotherFeatures from "./AnotherFeatures";
import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import NavbarDemo from "./NavbarDemo";
import Rights from "./Rights";
import ServicesHeader from "./ServicesHeader";
import ToggleButton from "./ToggleButton";
import Values from "./Values";
import { useNavigate } from "react-router-dom";
import i1 from "../assets/k1.svg"
import i2 from "../assets/k2.svg"
import i3 from "../assets/k3.svg"
import i4 from "../assets/k4.svg"
import i5 from "../assets/k5.svg"
import img1 from "../assets/Icons 06.svg"
import img2 from "../assets/Icons 05.svg"
import img3 from "../assets/Icons 04.svg"

const BuisnessServicesPage = () => {
  const navigate = useNavigate()
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = document.getElementById("services-header").offsetHeight;

      if (scrollPosition >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-[#11071A] overflow-hidden">
      <div className="relative opacity-30">
                <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl"></div>
                <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl"></div>
                <div className="absolute opacity-60 top-60 -left-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-60 -right-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
      <div id="services-header">
        <ServicesHeader texts="Explore our diverse range of services designed to enhance engagement, create memorable events, and connect you with talented artists for any occasion." />
      </div>
      <div
        className={`${isSticky ? "fixed top-0 left-0 right-0 z-40 mt-24" : "relative mt-20"
          } flex justify-center `}
      >
        <ToggleButton />
      </div>
      <div className="relative z-20 top-80">
        <div className="absolute top-96 left-0 w-[300px] h-[300px] bg-[#650000] rounded-3xl mix-blend-screen filter opacity-30 blur-[100px]"></div>
        {/* <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '10vw', top: "100vh" }}></div> */}
        {/* <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '17vw', top: "95vh" }}></div> */}
      </div>
      <div className="pt-10">
        <AnotherFeatures
        src1={i1}
        src2={i2}
        src3={i3}
        src4={i4}
        src5={i5}
         t1="Team Engagement Activities"
          c1="Engage your team and boost morale with our creative workshops and team-building events. From health and wellness programs to cultural activities, we provide tailored experiences that encourage collaboration and inspire creativity."
          t2="Corporate Events"
          c2="Create a memorable experience with our well-planned corporate events. Whether it's an art and music festival, an awards ceremony, or a networking event, we take care of everything to make sure your event is a success."
          t3="Artist Booking"
          c3="Make your event planning easier with our artist booking services. We have a variety of talent available, so you can easily find and book the right performers or influencers for your needs."
          t4="Gifting & Merchandise"
          c4="Show appreciation with our eco-friendly corporate gifts. We offer personalized options and branded items that reflect your company's values and bring happiness to your clients and employees."
          t5="Corporate Talent Solutions"
          c5="Our Corporate Talent Solutions help businesses find the right talents for their needs. We specialize in connecting you with skilled professionals who can support your projects and initiatives. Whether you require temporary staffing or long-term recruitment, we provide tailored solutions to simplify your hiring process and ensure you have the best talent on your team." />
      </div>
      <div className="relative z-20 -top-80">
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#650000] rounded-3xl mix-blend-screen filter opacity-30 blur-[100px]"></div>
        {/* <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '10vw', top: "3vh" }}></div> */}
        {/* <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '17vw', top: "1vh" }}></div> */}
      </div>

      {/* <div className="relative opacity-40">
        <div className="absolute top-20 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
        <div className="absolute top-20 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
        <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
        <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
      </div>
      <div className="relative opacity-40">
        <div className="absolute top-10 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
        <div className="absolute top-10 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
        <div className="absolute opacity-60 top-80 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
         <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
      </div> */}

      <div className="flex flex-col items-center my-8 sm:my-16">
        <p className="p-2 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] font-poppins">Our Product</p>
        <p className="text-white text-[32px] sm:text-5xl uppercase font-bebas"> explore <span className="gradient_text">{" "}our platform </span> </p>
      </div>
      <Values  img1={img1} img2={img2} img3={img3} t1="Discover and Explore Talents"
        t2="Seamless Booking Process"
        t3="Manage Your Events Effortlessly"
        c1="Explore a wide range of talented artists available for for various occasions. Use simple filters to find the perfect match based on category, location, or budget."
        c2="Book artists for your requirements with just a few clicks. Choose the dates and times that fit your schedule, and confirm your bookings quickly."
        c3="Stay organized with our tools that help you plan and manage your events effectively, keeping everything on track from start to finish." />
      <div className="flex justify-center mt-10">
        <button
          onClick={() => navigate("/business/features")}
          className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-6 rounded-xl p-3 font-light text-white font-poppins"
        >
          All Features
        </button>
      </div>
      <GetInTouch />
      <Footer />
      <Rights />
    </div>
  );
};

export default BuisnessServicesPage;
