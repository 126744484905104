import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img from "../assets/Vector.png";
import img1 from "../assets/Calendar.svg"
import img2 from "../assets/SecondImage.png"
import img3 from "../assets/Invoice.svg"

const Features = () => {
  const navigate = useNavigate();
  const features = [
    { id: "display1", src: "https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2FQ1.svg?alt=media&token=085fba4c-c4c8-44e0-949e-f20a21cf409e", title: "Portfolio & Press Kits", description: "Build a stunning online portfolio, create new age digital press kits (EPKs), and customize your profile to showcase your creativity in the best way." },
    { id: "display2", src: img2, title: "Find Transparent Gigs", description: "Find and apply for gigs with transparent opportunities, connect directly with clients, and secure paid performances without the usual hassle." },
    { id: "display3", src: img1, title: "Seamless Calendar Management", description: "Keep track of all your events and schedules efficiently with a smart calendar that organizes your gigs, meetings, and collaborations in one place." },
    { id: "display4", src: img3, title: "Simple Invoice Management", description: "Create, track, and send invoices easily to clients, streamlining financial processes and ensuring you get paid accurately and on time." },
    { id: "display5", src: "https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2FQ2.svg?alt=media&token=765566bf-3d62-4a4e-a767-d84e5dfc0a7c", title: "Jams & More", description: "Connect with fellow artists for jam sessions, collaborations, and creative projects. More exciting features coming soon to help you grow and thrive!" }
  ];

  const [display, setDisplay] = useState("display1");
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const autoSwitchTime = 7000;

  const handleChange = (e) => setDisplay(e.target.id);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.4 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    const interval = setInterval(() => {
      const currentIndex = features.findIndex(feature => feature.id === display);
      const nextIndex = (currentIndex + 1) % features.length;
      setDisplay(features[nextIndex].id);
    }, autoSwitchTime);

    return () => clearInterval(interval);
  }, [display, isVisible, features]);

  const currentFeature = features.find(feature => feature.id === display);

  return (
    <div ref={componentRef} className="text-white text-lg">
      <div className="relative z-10">
        <div className="flex flex-col items-center">
        <p className=" text-white  text-center sm:leading-[4rem] tracking-wide font-normal text-[32px] sm:text-5xl uppercase font-bebas mb-8 sm:my-16">
                    All-in-One Artist {" "}
                    <span className="gradient_text">
                        toolkit
                    </span>
                </p>
          <div className="px-[16px] md:px-24 w-full overflow-hidden font-poppins font-extralight">
          <div className="w-full rounded-[24px] sm:rounded-[56px] bg-gradient-to-r from-pink-500 to-blue-700 p-[1px] overflow-hidden">
              <div className="flex flex-col md:flex-row justify-between bg-black rounded-[24px] overflow-hidden sm:rounded-[56px] h-[810px] sm:h-[550px]">
                <div className="md:w-[40%] w-full flex items-center justify-center">
                  <img
                    src={currentFeature.src}
                    className={`h-[350px] w-[422px] sm:h-full sm:w-full object-cover rounded-s-[24px] sm:rounded-s-[56px] transition-transform duration-1000 ease-in-out ${display === currentFeature.id ? "opacity-100 scale-100" : "opacity-0 scale-95"}`}
                    alt={currentFeature.title}
                  />
                </div>
                <div className="h-[460px] sm:h-full flex flex-col w-full md:w-[60%] sm:border-s border-slate-600" style={{ boxShadow: "-4px 0 8px -2px rgba(13, 42, 148, 0.8)" }}>
                  {features.map((feature) => (
                    <div id={feature.id} className="flex-grow flex flex-col items-start border-b border-slate-600 p-4 relative " onClick={handleChange}>
                      <button
                        id={feature.id}
                        className={`${display === feature.id ? "text-white" : "text-gray-500"} font-normal sm:mb-2 text-[16px] sm:text-[24px]`}         
                      >
                        {feature.title}
                      </button>
                      <div
                        className={`${display === feature.id ? "max-h-96 opacity-100 translate-y-0" : "max-h-0 opacity-0 translate-y-10"} transition-all duration-1000 ease-in-out overflow-hidden`}
                      >
                        <p className="text-gray-400 text-[14px] md:text-base">
                          {feature.description}
                        </p>
                        <button onClick={() => navigate('/features')} className="font-normal mt-[16px] flex gap-1 gradient_text">
                          Explore more
                          <img src={img} alt="vector" className="mt-2" />
                        </button>
                      </div>
                      <div
                        className={`absolute bottom-0 left-0 h-1 bg-gradient-to-r from-purple-600 to-orange-600 ${display === feature.id ? "animate-timer" : ""}`}
                        style={{ width: display === feature.id ? "100%" : "0%" }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes timer {
          from { width: 0%; }
          to { width: 100%; }
        }
        .animate-timer {
          animation: timer ${autoSwitchTime}ms linear;
        }
      `}</style>
    </div>
  );
};

export default Features;
