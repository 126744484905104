import { BrowserRouter, Route, Routes} from "react-router-dom"
import Home from "./components/Home"
import ServicesPage from "./components/ServicesPage"
import FeaturesPage from "./components/FeaturesPage"
import AboutusPage from "./components/AboutusPage"
import BlogsAndMedia from "./components/BlogsAndMedia"
import EnquiryForm from "./components/EnquiryForm"
import PageIcons from "./components/PageIcons"
import BuisnessPage from "./components/BuisnessPage"
import Extra from "./components/Extra"
import BuisnessFeaturesPage from "./components/BuisnessFeaturesPage"
import BuisnessServicesPage from "./components/BuisnessServicesPage"
import Submit from "./components/Submit"
import ScrollToTop from "./components/ScrollToTop"
import TermsAndCond from "./components/TermsAndCond"
import PrivacyPolicy from "./components/PrivacyPolicy"
import { PostBlog } from "./components/PostBlog"
import Blog from "./components/Blog"

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/services" element={<ServicesPage/>}/>
      <Route path="/features" element={<FeaturesPage/>}/>
      <Route path="/about" element={<AboutusPage/>}/>
      <Route path="/blogs" element={<BlogsAndMedia/>}/>
      <Route path="/enquiryform" element={<EnquiryForm/>}/>
      <Route path="/business/" element={<BuisnessPage/>}/>
      <Route path="/business/features" element={<BuisnessFeaturesPage/>}/>
      <Route path="/business/services" element={<BuisnessServicesPage/>}/>
      <Route path="/extra" element={<Extra/>}/>
      <Route path="/submit" element={<Submit/>}/>
      <Route path="/terms_and_condiitons" element={<TermsAndCond/>}/>
      <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
      <Route path="create-blog" element={<PostBlog />} /> 
      <Route path="/blog" element={<Blog />} />
    </Routes>
    <div>
      <PageIcons/>
    </div>
    </BrowserRouter>
  )
}

export default App