import React, { forwardRef, useState } from "react";
import { PiMicrophoneStageLight } from "react-icons/pi";
import { CiUser } from "react-icons/ci";
import NavbarDemo from "./NavbarDemo";
import Footer from "./Footer";
import Rights from "./Rights";
import Submit from "./Submit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
        className="rounded-xl p-3 w-[308px] bg-[#11071ad6] text-white outline-none"
        onClick={onClick}
        ref={ref}
        value={value||""}
        placeholder="Booking date"
        readOnly
    />
));

const EnquiryForm = () => {
    const [showSubmit, setShowSubmit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [formData, setFormData] = useState({ type: "book" })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filled, setFilled] = useState({
        artistCategory: false,
        event: false,
        date: false,
        genre: false,
        location: false,
        name: false,
        email: false,
        contactNumber: false,
        budgetRange: false,
        description: false
    });

    const handleInputChange = (e, field) => {
        setFilled(prev => ({
            ...prev,
            [field]: e.target.value !== ""
        }));

        setFormData({
            ...formData, [e.target.id]: e.target.value
        })
        console.log(formData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        // Validate form fields
        const requiredFields = [
            "artistCategory",
            "eventType",
            "bookingDate",
            "genre",
            "location",
            "name",
            "email",
            "contact",
            "budgetRange",
        ];
        const missingFields = requiredFields.filter((field) => !formData[field]);
        if (missingFields.length > 0) {
            setError(`Please fill the required fields: ${missingFields.join(", ")}`);
            console.log("here")
            setLoading(false);
            return;
        }
        try {
            const response = await axios.post(
                "https://tv-server-ql7l.onrender.com/api/send-mail",
                formData
            );
            console.log("Email sent:", response.data);
            setFormData({ type: "book" });
            setShowSubmit(true);
            setLoading(false);
        } catch (error) {
            console.error("Error sending email:", error);
            setError("Failed to send email. Please try again.");
            setLoading(false);
        }
    };

    const handleCloseSubmit = () => {
        setShowSubmit(false);
    };


    const getBorderColor = (isFilled) =>
        isFilled ? "bg-gradient-to-r from-[#873795] to-[#D76D5E]" : "bg-gradient-to-r from-[#D76D5E60] to-[#134FE966]";

    return (
        <div className="bg-[#11071a] font-poppins">
             <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <div className="gradient-border rounded-3xl w-[350px] sm:w-[700px] max-w-5xl mx-auto my-28">
                <div className="bg-[#11071ad6] rounded-3xl text-white p-2">
                    <p className="p-2 font-normal text-lg">Explain your requirement, we will do the rest.</p>
                    <p className="px-2 font-extralight">
                        Our team will handpick the best artist from the <span className="font-medium">”Talentverse Community”</span> for your event and get back to you in no time.
                    </p>
                    <div className="flex items-center px-2 pt-2">
                        <PiMicrophoneStageLight className="mr-2" />
                        <p>Gig Details</p>
                    </div>
                    <form className="bg-transparent mt-2 p-2" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.artistCategory)} rounded-xl`}>
                                <select
                                    id="artist-category"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    defaultValue=""
                                    onChange={(e) => handleInputChange(e, "artistCategory")}
                                >
                                    <option value="" disabled hidden>Select Artist Category</option>
                                    <option value="option1">Singer</option>
                                    <option value="option2">Music Band</option>
                                    <option value="option3">Instrumentalist</option>
                                    <option value="option4">Artisans/Hand-crafters</option>
                                    <option value="option5">Dancer</option>
                                    <option value="option6">DJ</option>
                                    <option value="option7">Emcee/Host</option>
                                    <option value="option8">Flow Artist</option>
                                    <option value="option9">Magician</option>
                                    <option value="option10">Mentalist</option>
                                    <option value="option11">Standup Comedian</option>
                                    <option value="option12">Influencer</option>
                                    <option value="option13">Painter/Sketch Artist</option>
                                    <option value="option14">Poet</option>
                                    <option value="option15">Other</option>
                                </select>
                            </div>

                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.genre)} rounded-xl`}>
                                <select
                                    id="event"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    defaultValue=""
                                    onChange={(e) => handleInputChange(e, "event")}
                                >
                                    <option value="" disabled hidden>Select Event Type</option>
                                    <option value="option1">Cafe</option>
                                    <option value="option2">Hotel</option>
                                    <option value="option3">Lounge/Bar/Club</option>
                                    <option value="option4">Wedding</option>
                                    <option value="option5">Private Event</option>
                                    <option value="option6">Corporate Event</option>
                                    <option value="option7">Public Event</option>
                                    <option value="option8">Corporate Event</option>
                                    <option value="option9">Private Event</option>
                                    <option value="option10">Institute Event</option>
                                    <option value="option11">Online Event</option>
                                    <option value="option12">Other</option>
                                </select>
                            </div>

                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.date)} rounded-xl overflow-hidden`}>
                                <DatePicker className="rounded-xl overflow-hidden w-[308px]"
                                    id="bookingDate"
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date); // Save the date
                                        handleInputChange({ target: { id: "bookingDate", value: date } }, "date");
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    customInput={<CustomInput />}
                                />
                            </div>

                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.contactNumber)} rounded-xl`}>
                                <input
                                    type="text"
                                    placeholder="Enter Genre"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "genre")}
                                />
                            </div>
                        </div>

                        <div className={`p-[1px] w-[310px] sm:w-[649px] ${getBorderColor(filled.location)} rounded-xl mt-4`}>
                            <input
                                type="text"
                                placeholder="Select Location"
                                className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                onChange={(e) => handleInputChange(e, "location")}
                            />
                        </div>

                        <div className="flex items-center mt-2 p-2">
                            <CiUser className="mr-2" />
                            <p>Personal Details</p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.name)} rounded-xl`}>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "name")}
                                />
                            </div>
                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.email)} rounded-xl`}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "email")}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.contactNumber)} rounded-xl`}>
                                <input
                                    type="text"
                                    placeholder="Contact Number"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "contactNumber")}
                                />
                            </div>
                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.budgetRange)} rounded-xl`}>
                                <input
                                    type="text"
                                    placeholder="Budget Range"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "budgetRange")}
                                />
                            </div>
                        </div>

                        <div className={`p-[1px] w-[310px] sm:w-[649px] ${getBorderColor(filled.description)} rounded-xl mt-4`}>
                            <textarea
                                rows={4}
                                placeholder="Help us understand what you're looking for"
                                className="rounded-xl pt-3 px-3 w-full bg-[#11071ad6] text-white outline-none"
                                onChange={(e) => handleInputChange(e, "description")}
                            />
                        </div>

                        <div className="mt-6 flex justify-end">
                            <button type="submit" className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-8 py-3 rounded-xl font-semibold text-white">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            <Rights />
            {showSubmit && <Submit onClose={handleCloseSubmit} />}
        </div>
    );
};

export default EnquiryForm;
