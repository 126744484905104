import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalWithVideo = ({ isOpen, onRequestClose, videoSrc }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-class"
      overlayClassName="overlay-class"
    >
      <div className="flex justify-center items-center h-[35vh] md:h-[50vh] w-[70vw] md:w-[50vw]">
        <iframe
          width="100%"
          height="100%"
          src={`${videoSrc}?autoplay=1&controls=0&rel=0&modestbranding=1&iv_load_policy=3`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          className="w-full h-full rounded-[16px]"
        ></iframe>
      </div>
    </Modal>
  );
};

export default ModalWithVideo;
