import React, { useState, memo } from "react";
import { cn } from "../lib/util";

const Card = memo(({ card, index, hovered, setHovered }) => (
  <div
    onMouseEnter={() => setHovered(index)}
    onMouseLeave={() => setHovered(null)}
    className={cn(
      "relative overflow-hidden transition-all duration-300 ease-out ",
      hovered !== null && hovered !== index && "blur-sm scale-[0.98]"
    )}
    style={{
      width: card.customWidth,
      height: card.customHeight,
      margin: "5px",
    }}
  >
   
    <div
      className="absolute h-full w-full flex flex-col items-center rounded-3xl overflow-hidden"
      style={{
        padding: "6px",
        borderRadius: "25px",
        background: hovered === index
          ? `linear-gradient(${card.hoverGradient})`
          : `linear-gradient(${card.borderGradient})`,
      }}  >
      <img
        src={card.src}
        alt={card.title}
        className="object-cover h-full w-full rounded-3xl bg-black"
        style={{ borderRadius: "22px" }}
      />
      <div
        className={cn(
          "absolute bg-black/40 filter backdrop-blur-[5px] bg-opacity-100 flex items-center p-3 rounded-b-[22px] left-1.5 bottom-1.5 right-1.5 transform transition-transform duration-500",
          hovered === index ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
        )}>
        <div
          className={cn(
            "text-[14px] font-poppins sm:text-[18px] font-normal bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-200 ",           
          )}>
          {card.title}
        </div>
      </div>
    </div>
  </div>
));

Card.displayName = "Card";

export function FocusCards({ cards }) {
  const [hovered, setHovered] = useState(null);
  console.log(hovered);

  return (
    <div className="flex flex-wrap justify-center items-center">
      <div className="flex flex-col items-center my-8 sm:my-16">
        <p className="p-2 opacity-80 text-[#F5E1FF] text-[14px] sm:text-[18px] ">Events</p>
        <p className="text-white text-[32px] sm:text-5xl font-normal font-bebas">
          OUR {" "}
          <span className="gradient_text">
            UNPARALLELED
          </span>
          {" "} EVENTS
        </p>
      </div>
      <div className="flex flex-wrap justify-center px-1 sm:px-20 w-full">
        {cards.map((card, index) => (
          <Card
            key={card.title}
            card={card}
            index={index}
            hovered={hovered}
            setHovered={setHovered}
          />
        ))}
      </div>
    </div>
  );
}
