import React from "react";
import { MeteorsDemo } from "./MeteorsDemo";

const Preposition = () => {
  return (
    <div className="w-full flex flex-col items-center pb-10">
      <div className="flex flex-col items-center my-8 sm:my-16">
        <p className="p-2 text-[14px] sm:text-[18px] opacity-80 text-[#F5E1FF] ">Value Preposition</p>
        <p className="text-white font-normal leading-[4rem] tracking-wide text-[32px] sm:text-5xl font-bebas">
          WHY {" "}
          <span className="gradient-text inline-block">
            TALENTVERSE
          </span>
          {" "} ?
        </p>
      </div>
      <div className="grid sm:grid-cols-3 gap-10 mb-10 font-poppins font-extralight">
        <MeteorsDemo
          title="Showcase Your Work" 
          description="Build a stunning online portfolio to highlight your talents and projects. Easily present your work to attract potential collaborators and gigs."
  
        />
        <MeteorsDemo
          title="Transparent Opportunities"
          description="Access a variety of clear gig opportunities. Focus on your art with no hidden surprises in the booking process."
        />
        <MeteorsDemo
          title="Collaborate and Connect"
          description="Join a vibrant community of artists across different art forms. Network, share ideas, and grow together in a supportive environment."
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-10 mb-10">
        <MeteorsDemo
          title="Stay Organized"
          description="Manage your schedule effortlessly with our integrated calendar feature. Keep track of gigs, rehearsals, and collaboration sessions."
        />
        <MeteorsDemo
        colour="#943451"
          title="Go Beyond Skills"
          description="Step outside your comfort zone and discover new art forms. Talentverse encourages experimentation and innovation, empowering you to express yourself in new ways."
        />
      </div>
    </div>
  );
};

export default Preposition;
