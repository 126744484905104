// import img from "../assets/Frame62.png";
import blog from "../assets/BlogImage.png";

export const blogsData = [
  {
    img: blog,
    category: "Technology",
    timeToRead: "6 min read",
    heading: "Guide to Starting Your Career as an Independent Artist in 2025",
    description:
      "Starting your career as an independent artist in 2025 can be both exciting and daunting. With the rise of digital platforms and the ever-evolving art landscape, there are numerous opportunities to explore. This guide to starting your career as an independent artist in 2025 will provide you with essential tips and strategies to navigate this journey successfully. Whether you are a musician, visual artist, or any other type of creative, this guide will help you understand how to be an independent artist in today’s world.",
  },
  {
    img: blog,
    category: "Technology",
    timeToRead: "6 min read",
    heading: "Guide to Starting Your Career as an Independent Artist in 2025",
    description:
      "Starting your career as an independent artist in 2025 can be both exciting and daunting. With the rise of digital platforms and the ever-evolving art landscape, there are numerous opportunities to explore. This guide to starting your career as an independent artist in 2025 will provide you with essential tips and strategies to navigate this journey successfully. Whether you are a musician, visual artist, or any other type of creative, this guide will help you understand how to be an independent artist in today’s world.",
  },
  {
    img: blog,
    category: "Technology",
    timeToRead: "6 min read",
    heading: "Guide to Starting Your Career as an Independent Artist in 2025",
    description:
      "Starting your career as an independent artist in 2025 can be both exciting and daunting. With the rise of digital platforms and the ever-evolving art landscape, there are numerous opportunities to explore. This guide to starting your career as an independent artist in 2025 will provide you with essential tips and strategies to navigate this journey successfully. Whether you are a musician, visual artist, or any other type of creative, this guide will help you understand how to be an independent artist in today’s world.",
  },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature4",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature5",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature6",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature3",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature4",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature5",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
  // {
  //   img: img,
  //   category: "Technology",
  //   timeToRead: "6 min read",
  //   heading: "Heading of Feature6",
  //   description:
  //     "Lorem ipsum dolor sit consectetur adipiscing. Mauris scelerisque euismod ante mpor. Curabitur rutrum id odio sit amet efficitur. Vestibulum tempor mattis mollis.",
  // },
];

export const mediaData = [
  {
    id: "01",
    link: "https://www.youtube.com/embed/SZ0jYEVpDlY?si=UsrOdB2uspSI3NCk",
    category: "Technology",
    heading: "Raw Emotions and Lyrical Brilliance",
  },
  {
    id: "02",
    link: "https://www.youtube.com/embed/uRgkY4bYrgU?si=4cHvWpxv8KvgXRlx",
    category: "Technology",
    heading: "Abisheak Samson's Boxing Journey",
  },
  {
    id: "03",
    link: "https://www.youtube.com/embed/4E3cIo7n0Rw?si=gKmxzP4e_VHcIe3C",
    category: "Technology",
    heading: "Mere Doodle Has Now Become a Very Important Part of #Life",
  },
];
