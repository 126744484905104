import React, { useEffect, useState } from "react"
import FeaturesHeader from "./FeaturesHeader"
import Footer from "./Footer"
import GetInTouch from "./GetInTouch"
import NavbarDemo from "./NavbarDemo"
import Rights from "./Rights"
import Values from "./Values"
import { WobbleCardDemo } from "./WobbleCardDemo"
import ToggleButton from "./ToggleButton"
import { useNavigate } from "react-router-dom"
import img1 from "../assets/Icons 03.svg"
import img2 from "../assets/Icons 02.svg"
import img3 from "../assets/Icons 01.svg"

const FeaturesPage = () => {
    const navigate = useNavigate()
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const headerElement = document.getElementById("features-header");

            if (headerElement) {
                const headerHeight = headerElement.offsetHeight;
                setIsSticky(scrollPosition >= headerHeight);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <div className="bg-[#11071A] overflow-hidden ">
            <div className="relative opacity-30">
                <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl"></div>
                <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl"></div>
                <div className="absolute opacity-60 top-60 -left-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-60 -right-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <div id="features-header">
                <FeaturesHeader />
            </div>
            <div className={`${isSticky ? "fixed top-0 left-0 right-0 z-40 mt-24" : "relative"
                } flex justify-center `}>
                <ToggleButton />
            </div>
            <WobbleCardDemo t1="Showcase, Find gigs, and manage your schedule with" t2="Talentverse" t3="Create and Customise Your Online Portfolio and Press Kit" c3="Easily create your profile to showcase your best work and skills with a personalized portfolio. Include images, videos, and links to present your talents effectively. Share your online press kit with clients to highlight your experience and expertise in one convenient location." t4="  Find Transparent Gigs" c4=" Explore a variety of gigs that fit your skills and interests. Our platform lets you search for opportunities based on your abilities, location, and budget. With straightforward details about each gig, you can make smart choices and find the right projects for you." t5=" Manage Your Calendar" c5="Keep track of all your gigs and events in one easy-to-use calendar. Our calendar feature helps you view your booked dates, manage availability, and avoid double bookings. You can easily sync your events with personal calendars, making it simple to stay organized and on top of your schedule." t6="Simplify Your Invoice Management Process" c6="Take the stress out of billing with our simple invoice management system. Create and send invoices for your gigs quickly, ensuring you get paid on time. You can also keep track of your earnings and payment history in one place, allowing you to manage your finances easily." t7="Stay Updated with Notifications About Your Activities" c7="Receive real-time notifications to keep you informed about bookings, inquiries, and profile views. Our notification system ensures you never miss important messages from clients or updates about your gigs. Stay connected and responsive, improving your chances of securing more opportunities." t8="New Features Coming Soon" c8="Exciting new features are on the way to help you grow and connect with other artists. Our commitment is to provide you with the best tools to support your artistic journey and help you succeed in your art form." />
            <div className="flex flex-col items-center my-8 sm:my-16">
                <p className="text-white text-[32px] sm:text-5xl uppercase font-bebas"> explore {" "} <span className="gradient_text">{" "} our services </span></p>
            </div>
            <div className="relative opacity-40">
                <div className="absolute top-20 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                {/* <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div> */}
            </div>
            <Values  img1={img1} img2={img2} img3={img3} t1="Artist Management and Bookings" t2="Branding and Online Presence" t3="Content Creation and Production " c1="Get help managing your career with support in talent management, contract guidance, and planning for your goals. We work with you to make sure you are prepared for opportunities and on the right path to long-term success." c2="Grow your brand with a custom logo, personal website, and artwork that shows who you are. We also manage your social media on platforms like Instagram and YouTube to help you connect with your audience." c3="Improve your profile with quality photos, videos, and reels. We offer audio and video production services, plus studio recording for musicians, voice actors, and other artists to show off your work professionally." />
            <div className="flex justify-center mt-10">
                <button
                    onClick={() => navigate("/services")}
                    className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-6 rounded-xl p-3 font-light text-white font-poppins"
                >
                    All Services
                </button>
            </div>
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default FeaturesPage