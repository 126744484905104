import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img from "../assets/Vector.png"
import img3 from "../assets/Invoice.svg"
import img1 from "../assets/Image 2.png"
import img2 from "../assets/Image 3.png"
import img0 from "../assets/img0.svg"

const BuisnessFeatures = () => {
  const navigate = useNavigate()
  const features = [
    { id: "display1", src: img0, title: "Artist Discovery", description: "Easily find and connect with a diverse range of talented artists for your events. Talentverse helps you discover the perfect fit for any occasion with just a few clicks." },
    { id: "display2", src: img1, title: "Booking & Scheduling", description: "Streamline your event planning with our hassle free booking system. Schedule performances effortlessly and ensure that your events run smoothly and on time." },
    { id: "display3", src: img2, title: "Calendar Integration", description: "Stay organized with integrated calendar features that sync your bookings. Never miss an important date, and manage all your events in one convenient place." },
    { id: "display4", src: img3, title: "Invoice Managementt", description: "Keep track of all your transactions with our user friendly invoice management system. Ensure transparent billing and streamline payments for all your artists." },
    { id: "display5", src: "https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/images%2FF1.svg?alt=media&token=7276cede-c7cb-4803-8719-0678f5f79d5c", title: "Exciting New Features Coming Soon!", description: "We're constantly improving and adding new tools to enhance your experience. Stay tuned for upcoming features designed to make your event planning even easier!" }
  ];
  const [display, setDisplay] = useState("display1");
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const autoSwitchTime = 7000;

  const handleChange = (e) => setDisplay(e.target.id);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.4 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      const currentIndex = features.findIndex(feature => feature.id === display);
      const nextIndex = (currentIndex + 1) % features.length;
      setDisplay(features[nextIndex].id);
    }, autoSwitchTime);

    return () => clearInterval(interval);
  }, [display, isVisible, features]);

  const currentFeature = features.find(feature => feature.id === display);

  return (
    <div className="text-white text-lg">
      <div className="relative z-10">
        <div className="flex flex-col items-center mt-8 sm:mt-16">
          <p className="p-2 text-[14px] sm:text-[18px] text-[#F5E1FF] opacity-80 ">Features</p>
          <p className="leading-[4rem] tracking-wide text-white font-normal text-[32px] sm:text-5xl mb-8 sm:mb-16 uppercase font-bebas">
            Our Featuers For {" "}
            <span className="gradient_text">
              The Future
            </span>
          </p>
          <div className="px-[16px] md:px-24 w-full overflow-hidden font-poppins font-extralight">
          <div className="w-full rounded-[24px] sm:rounded-[56px] bg-gradient-to-r from-pink-500 to-blue-700 p-[1px] overflow-hidden">
              <div className="flex flex-col md:flex-row justify-between bg-black rounded-[24px] overflow-hidden sm:rounded-[56px] h-[810px] sm:h-[550px]">
                <div className="md:w-[40%] w-full flex items-center justify-center">
                  <img
                    src={currentFeature.src}
                    className={`h-[350px] w-[422px] sm:h-full sm:w-full object-cover rounded-s-[24px] sm:rounded-s-[56px] transition-transform duration-1000 ease-in-out ${display === currentFeature.id ? "opacity-100 scale-100" : "opacity-0 scale-95"}`}
                    alt={currentFeature.title}
                  />
                </div>
                <div className="h-[460px] sm:h-full flex flex-col w-full md:w-[60%] sm:border-s border-slate-600" style={{ boxShadow: "-4px 0 8px -2px rgba(13, 42, 148, 0.8)" }}>
                  {features.map((feature) => (
                    <div id={feature.id} className="flex-grow flex flex-col items-start border-b border-slate-600 p-4 relative " onClick={handleChange}>
                      <button
                        id={feature.id}
                        className={`${display === feature.id ? "text-white" : "text-gray-500"} font-normal mb-2 text-[16px] sm:text-[24px]`}         
                      >
                        {feature.title}
                      </button>
                      <div
                        className={`${display === feature.id ? "max-h-96 opacity-100 translate-y-0" : "max-h-0 opacity-0 translate-y-10"} transition-all duration-1000 ease-in-out overflow-hidden`}
                      >
                        <p className="text-gray-400 text-[14px] md:text-base">
                          {feature.description}
                        </p>
                        <button onClick={() => navigate('/features')} className="font-normal mt-[16px] flex gap-1 gradient_text">
                          Explore more
                          <img src={img} alt="vector" className="mt-2" />
                        </button>
                      </div>
                      <div
                        className={`absolute bottom-0 left-0 h-1 bg-gradient-to-r from-purple-600 to-orange-600 ${display === feature.id ? "animate-timer" : ""}`}
                        style={{ width: display === feature.id ? "100%" : "0%" }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes timer {
          from { width: 0%; }
          to { width: 100%; }
        }
        .animate-timer {
          animation: timer ${autoSwitchTime}ms linear;
        }
      `}</style>
    </div>
  );
};


export default BuisnessFeatures;