import React, { useState } from "react";
import { blogsData, mediaData } from "../constants/MediaData.js";
import { Link } from "react-router-dom";
import ModalWithVideo from "./Modal";

const Media = () => {
  const [visibleItems, setVisibleItems] = useState(6);
  const [visibleItems2, setVisibleItems2] = useState(6);

  const [videoSrc, setVideoSrc] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSeeMore = () => {
    setVisibleItems((prev) => prev + 3);
  };

  const handleSeeMore2 = () => {
    setVisibleItems2((prev) => prev + 3);
  };

  const openModal = (src) => {
    setVideoSrc(src);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="">
      <div className="text-white font-medium px-4 sm:px-28 text-2xl pt-6 pb-2">
        <p>Blogs</p>
      </div>
      <div className="px-4 sm:px-24">
        <div className="grid sm:grid-cols-3 gap-10 rounded-[16px]">
          {blogsData.slice(0, visibleItems).map((feature, index) => (
            <Link to={`/blog?id=${index}`}>
              <div
                key={index}
                className="bg-gradient-to-r from-transparent to-transparent hover:from-[#134FE980] hover:via-[#6300FF80] hover:to-[#D76D5E80] p-[1px] rounded-[25px] hover:shadow-[0_0_20px_rgba(215,109,94,0.38),_0_0_20px_rgba(19,79,233,0.4)] "
              >
                <div className="bg-[#11071A] rounded-[25px]">
                  <div className="bg-transparent hover:bg-[#1B1B1B80] rounded-[25px] flex flex-col items-center p-4">
                    <img
                      src={feature.img}
                      alt={feature.heading}
                      className="w-full h-[250px] rounded-[16px] object-cover"
                    />
                    <span className="px-1 w-full">
                      <p className="py-2 text-white font-semibold text-lg w-full">
                        {feature.heading}
                      </p>
                      <p className="text-[#ffffffdb] text-[12px] sm:text-[14px] tracking-wide font-poppins font-extralight text-justify">
                        {feature.description.slice(0, 140) + "..."}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {/* {visibleItems < BlogsData.length && (
          <div className="flex justify-center mt-10">
            <button
              onClick={handleSeeMore}
              className="border border-1 border-[#FFFFFF40] text-white px-6 py-3 rounded-xl font-poppins font-extralight"
            >
              View More
            </button>
          </div>
        )} */}
      </div>
      <div className="px-4 sm:px-24 mt-20">
        <div className="flex flex-col items-center">
          <p className="text-white font-normal text-[32px] sm:text-5xl p-4 text-center uppercase font-bebas">
            Media Highlights
          </p>
          <p className="text-center text-[14px] sm:text-lg text-white sm:w-[50%] font-poppins font-extralight">
            Discover our highlights, interviews, and features in various media
            outlets that showcase our commitment to supporting artists and the
            creative community.
          </p>
        </div>
        <div className="py-14">
          <div className="grid sm:grid-cols-3 gap-10">
            {mediaData.slice(0, visibleItems2).map((feature, index) => (
              <div className="w-full" key={index}>
                <button
                  onClick={() => openModal(feature.link)}
                  key={index}
                  className="bg-gradient-to-r w-full h-full from-transparent to-transparent hover:from-[#134FE980] hover:via-[#6300FF80] hover:to-[#D76D5E80] p-[1px] rounded-[25px] hover:shadow-[0_0_20px_rgba(215,109,94,0.38),_0_0_20px_rgba(19,79,233,0.4)]"
                >
                  <div className="bg-[#11071A] rounded-[25px]">
                    <div className="bg-transparent hover:bg-[#1B1B1B80] rounded-[25px] flex flex-col items-center p-4">
                      <div className="h-[250px] overflow-hidden w-full rounded-[20px]">
                        <iframe
                          width="100%"
                          height="100%"
                          src={feature.link}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          style={{ pointerEvents: "none" }}
                        ></iframe>
                      </div>
                      <p className="py-2 text-white text-center font-medium text-lg w-full">
                        {feature.heading.slice(0, 35)} {feature.heading.length > 35? "...": " "}
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
          <ModalWithVideo
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            videoSrc={videoSrc}
          />
          {/* {visibleItems < MediaData.length && (
          <div className="flex justify-center mt-10">
            <button
              onClick={handleSeeMore2}
              className="border border-1 border-[#FFFFFF40] text-white px-6 py-3 rounded-xl font-poppins font-extralight"
            >
              View More
            </button>
          </div>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default Media;
