import { useScroll, useTransform, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export const Timeline = ({ data }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isLaptopView, setIsLaptopView] = useState(false);

  useEffect(() => {
    // Check viewport size
    const updateView = () => {
      setIsLaptopView(window.innerWidth >= 1024); // Adjust the breakpoint as needed
    };

    updateView(); // Run once on mount
    window.addEventListener("resize", updateView);

    return () => window.removeEventListener("resize", updateView);
  }, []);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [ref]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 1); // Adjust for small rounding errors
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const { scrollYProgress } = useScroll({
    container: containerRef,
    offset: ["start start", "end end"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
        <div
      className="w-full bg-[#11071A] font-poppins md:px-10 h-full sm:overflow-y-scroll sm:h-[30rem] custom-scrollbar"
      ref={containerRef}
      style={{
        boxShadow:
          isLaptopView && !isScrolledToBottom
            ? "0px -40px 40px -20px rgba(0, 0, 0, 0.8) inset"
            : "none",
      }}
    >
      <div ref={ref} className="relative max-w-7xl mx-auto pb-20">
        {data.map((item, index) => (
          <div key={index} className="flex justify-start pt-10 md:pt-20 md:gap-10">
            <div
              className="sticky flex flex-col md:flex-row z-20 items-center top-20 self-start max-w-xs lg:max-w-sm md:w-full"
            >
              <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-[#11071A] flex items-center justify-center">
                <div className="h-4 w-4 rounded-full bg-neutral-200 border-neutral-300 p-2" />
              </div>
              <h3 className="hidden md:block text-xl md:pl-20 md:text-3xl font-normal text-white">
                {item.title}
              </h3>  
            </div>

            <div className="relative text-[14px] pl-20 pr-4 md:pl-4 w-full">
              <p className="md:hidden block text-[18px] sm:text-xl mb-4 text-left font-medium text-white">
                {item.title}
              </p>
              {item.content}
            </div>
          </div>
        ))}
        <div
          style={{
            height: `${height}px`,
          }}
          className="absolute md:left-8 left-8 top-0 w-[2px] bg-gradient-to-b from-transparent via-neutral-200 to-transparent"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0 w-[2px] bg-gradient-to-t from-purple-500 via-blue-500 to-transparent"
          />
        </div>
      </div>
    </div>
  );
};
