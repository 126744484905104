// import img from "../assets/front.png";
import img3 from "../assets/ArrowSwip2.png"
import img from "../assets/hehe.png";
import ToggleButton from "./ToggleButton";
import { TextRevealCard } from "./TextRevealCard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const HeroSection = ({ src, t1, c1 }) => {
    const [hovered, setHovered] = useState(false)
    const navigate = useNavigate()
    return (
        <div className="h-[750px] text-white flex flex-col sm:flex-row items-center sm:items-start sm:justify-around">
            <div className="flex flex-col items-center sm:items-start mt-24 sm:mt-28 sm:p-10">
                <div className="w-[265px] sm:w-fit sm:px-4 font-['Poppins']">
                    <TextRevealCard text="Talentverse" revealText="Talentverse">
                    </TextRevealCard>
                </div>

                <div className="flex flex-col items-center sm:items-start sm:m-4 sm:px-10">
                    <p className="text-[24px] sm:text-5xl font-medium leading-[4rem] font-bebas uppercase">{t1}</p>
                    <p className="sm:mt-4 text-center sm:text-start text-[12px] sm:text-[16px] text-[#f5e1ff] opacity-100 font-light w-4/5 font-poppins">
                        {c1}
                    </p>
                </div>
                <div className="sm:hidden relative z-40">
                    <img
                        src={src}
                        alt=""
                        className="h-[350px] sm:h-[574px] sm:w-[632px] rounded-xl object-fill"
                    />
                </div>
                <div className="hidden sm:flex h-[40px] sm:px-10 hover:gradient_text sm:m-4 font-poppins font-extralight m-2">
                    <button className="text-[14px] sm:text-xl flex flex-col "
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => navigate("/enquiryform")}>
                        <div className="flex gap-4">
                            Enquire now
                            <img src={img3} alt="" className="sm:w-8 sm:h-8 w-5 h-5" />
                        </div>
                        {(hovered === true) && (
                            <img src={img} alt="underline" className="relative bottom-4 -left-5" />
                        )}
                    </button>
                </div>
                <div className="flex flex-col items-center sm:items-start ">
                    <p className="font-poppins font-light mb-4 sm:px-10 sm:mx-4 sm:mb-[1rem]">What describes you better.</p>
                </div>
                <div className="w-fit px-14 flex flex-col items-center sm:items-start ">
                    <ToggleButton />
                </div>
            </div>
            <div className="hidden sm:block relative sm:mr-24 sm:py-10 sm:mt-20 z-40">
                <img
                    src={src}
                    alt=""
                    className="h-[321px] sm:h-[574px] w-[361px] sm:w-[649px] rounded-xl object-contain"
                />
            </div>
        </div>
    );
}

export default HeroSection;
