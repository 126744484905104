import React, { forwardRef, useState } from "react";
import { PiMicrophoneStageLight } from "react-icons/pi";
import { CiUser } from "react-icons/ci";
import NavbarDemo from "./NavbarDemo";
import Footer from "./Footer";
import Rights from "./Rights";
import Submit from "./Submit";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//     <input
//         type="date"
//         className="rounded-xl p-3 w-[308px] bg-[#11071ad6] text-white outline-none"
//         onClick={onClick}
//         ref={ref}
//         value={value || ""}
//         placeholder="Booking date"
//         readOnly
//     />
// ));

const EnquiryForm = () => {
    const [showSubmit, setShowSubmit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [formData, setFormData] = useState({ type: "book" })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filled, setFilled] = useState({
        artistCategory: false,
        event: false,
        date: false,
        genre: false,
        location: false,
        name: false,
        email: false,
        contactNumber: false,
        budgetRange: false,
        description: false
    });

    const handleInputChange = (e, field) => {
        setFilled(prev => ({
            ...prev,
            [field]: e.target.value !== ""
        }));

        setFormData({
            ...formData, [e.target.id]: e.target.value
        })
        console.log(formData);
    };

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(number);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        // Validate form fields
        const requiredFields = [
            "artistCategory",
            "event",
            "bookingDate",
            "genre",
            "location",
            "name",
            "email",
            "contactNumber",
            "budgetRange",
            "desription"
        ];
        const missingFields = requiredFields.filter((field) => !formData[field]);
        if (missingFields.length > 0) {
            setError(`Please fill the required fields: ${missingFields.join(", ")}`);
            console.log("here")
            setLoading(false);
            return;
        }
        if (!validateEmail(formData.email)) {
            setError("Invalid email address. Please enter a valid email.");
            setLoading(false);
            return;
        }
        if (!validatePhoneNumber(formData.contact)) {
            setError("Invalid phone number. Please enter a 10-digit number.");
            setLoading(false);
            return;
        }
        try {
            const response = await axios.post(
                "https://tv-server-ql7l.onrender.com/api/send-mail",
                formData
            );
            console.log("Email sent:", response.data);
            setFormData({ type: "book" });
            setShowSubmit(true);
            setLoading(false);
        } catch (error) {
            console.error("Error sending email:", error);
            setError("Failed to send email. Please try again.");
            setLoading(false);
        }
    };

    const handleCloseSubmit = () => {
        setShowSubmit(false);
    };


    const getBorderColor = (isFilled) =>
        isFilled ? "bg-gradient-to-r from-[#873795] to-[#D76D5E]" : "bg-gradient-to-r from-[#D76D5E60] to-[#134FE966]";

    return (
        <div className="bg-[#11071a] font-poppins">
            <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <div className="gradient-border rounded-3xl w-[350px] sm:w-[700px] max-w-5xl mx-auto my-28">
                <div className="bg-[#11071ad6] rounded-3xl text-white p-2">
                    <p className="p-2 font-normal text-lg">Explain your requirement, we will do the rest.</p>
                    <p className="px-2 font-extralight">
                        Our team will handpick the best artist from the <span className="font-medium">”Talentverse Community”</span> for your event and get back to you in no time.
                    </p>
                    <div className="flex items-center px-2 pt-2">
                        <PiMicrophoneStageLight className="mr-2" />
                        <p>Gig Details</p>
                    </div>
                    <form className="bg-transparent mt-2 p-2" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.artistCategory)} rounded-xl`}>
                                <select
                                    id="artistCategory"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white/60 outline-none"
                                    defaultValue=""
                                    onChange={(e) => handleInputChange(e, "artistCategory")}
                                >
                                    <option value="" disabled hidden>Select Artist Category</option>
                                    {[
                                        "Singer",
                                        "Music Band",
                                        "Instrumentalist",
                                        "Artisans/Hand-crafters",
                                        "Dancer",
                                        "DJ",
                                        "Emcee/Host",
                                        "Flow Artist",
                                        "Magician",
                                        "Mentalist",
                                        "Standup Comedian",
                                        "Influencer",
                                        "Painter/Sketch Artist",
                                        "Poet",
                                        "Other"
                                    ].map((item, index) => (
                                        <option key={index} className="text-white" value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.event)} rounded-xl`}>
                                <select
                                    id="eventType"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white/60 outline-none"
                                    defaultValue=""
                                    onChange={(e) => handleInputChange(e, "event")}
                                >
                                    <option value="" disabled hidden>Select Event Type</option>
                                    {
                                        [
                                            "Cafe",
                                            "Hotel",
                                            "Lounge/Bar/Club",
                                            "Wedding",
                                            "Private Event",
                                            "Corporate Event",
                                            "Public Event",
                                            "Corporate Event",
                                            "Private Event",
                                            "Institute Event",
                                            "Online Event",
                                            "Other"
                                        ].map((item, index) => (
                                            <option key={index} className="text-white" value={item} >
                                                {item}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className={`p-[1px] w-[310px] ${getBorderColor(filled.date)} rounded-xl overflow-hidden`}>
                                {/* <DatePicker className="rounded-xl overflow-hidden w-[308px]"
                                    id="bookingDate"
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date); // Save the date
                                        handleInputChange({ target: { id: "bookingDate", value: date } }, "date");
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    customInput={<CustomInput />}
                                /> */}

                                <input
                                    id="bookingDate"
                                    type="date"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white/60 outline-none"
                                    min={new Date().toISOString().split("T")[0]}
                                    onChange={(e) => handleInputChange(e, "date")}
                                />

                            </div>
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.genre)} rounded-xl`}>
                                <input
                                    id="genre"
                                    type="text"
                                    placeholder="Enter Genre"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "genre")}
                                />
                            </div>
                        </div>

                        <div className={`p-[1px] w-[314px] sm:w-[654px] ${getBorderColor(filled.location)} rounded-xl mt-4`}>
                            <input
                                id="location"
                                type="text"
                                placeholder="Enter city name"
                                className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                onChange={(e) => handleInputChange(e, "location")}
                            />
                        </div>

                        <div className="flex items-center mt-2 p-2">
                            <CiUser className="mr-2" />
                            <p>Personal Details</p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.name)} rounded-xl`}>
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "name")}
                                />
                            </div>
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.email)} rounded-xl`}>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    onChange={(e) => handleInputChange(e, "email")}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.contactNumber)} rounded-xl`}>
                                <input
                                    id="contactNumber"
                                    type="text"
                                    pattern="\d*"
                                    inputMode="numeric"
                                    maxLength={10}
                                    placeholder="Contact Number"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white outline-none"
                                    value={formData.contactNumber || ""}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, "");
                                        handleInputChange({ target: { id: "contactNumber", value } }, "contactNumber");
                                    }}
                                />
                            </div>

                            <div className={`p-[1px] w-[314px] ${getBorderColor(filled.budgetRange)} rounded-xl`}>
                                <select
                                    id="budgetRange"
                                    className="rounded-xl p-3 w-full bg-[#11071ad6] text-white/60 outline-none"
                                    defaultValue=""
                                    onChange={(e) => handleInputChange(e, "budgetRange")}
                                >
                                    <option value="" disabled hidden>Budget Range</option>
                                    {
                                        ["₹10001 - ₹20000",
                                            "₹20001 - ₹35000",
                                            "₹35001 - ₹50000",
                                            "₹50001 - ₹75000",
                                            "₹75001 - ₹100000",
                                            "₹100001 - ₹150000",
                                            "₹150001 - ₹200000",
                                            "₹200001 - ₹300000",
                                            "₹300001 - ₹500000",
                                            "₹500001 - ₹1000000"

                                        ].map((item, index) => (
                                            <option key={index} className="text-white" value={item} >
                                                {item}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={`p-[1px] w-[314px] sm:w-[654px] ${getBorderColor(filled.description)} rounded-xl mt-4`}>
                            <textarea
                                id="additionalInfo"
                                rows={4}
                                placeholder="Help us understand what you're looking for"
                                className="rounded-xl pt-3 px-3 w-full bg-[#11071ad6] text-white outline-none"
                                onChange={(e) => handleInputChange(e, "description")}
                            />
                        </div>
                        <div className="mt-6 flex justify-end">
                            <button type="submit" className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-8 py-3 rounded-xl font-semibold text-white">
                                {loading ? "Loading..." : "Submit"}
                            </button>
                        </div>
                        <div>
                            {error && <p className="text-red-600">{error}</p>}
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            <Rights />
            {showSubmit && <Submit onClose={handleCloseSubmit} />}
        </div>
    );
};

export default EnquiryForm;
