import React, { useState } from 'react';
import img1 from "../assets/ArrowSwip2.png"
import img2 from "../assets/SwipArrowhover.png"
import img3 from "../assets/one.png"
import img4 from "../assets/two.png"
import img5 from "../assets/three.png"
import img6 from "../assets/five.png"
const testimonials = [
  {
    id: 1,
    image: img3,
    name: 'Vasanth',
    title: 'Beatboxer',
    quote: 'Loving it being a part of this vibrant community at Talentverse filled with crazy and talented musicians!! More places to go!! Keep killing it 🔥',
  },
  {
    id: 2,
    image: img4,
    name: 'Joel Siju Varghese',
    title: 'Singer Guitarist',
    quote: 'I came to know about Talentverse few months after I came to Bangalore. I am from Kerala. I am a Music teacher now teaching guitar, ukulele and western vocals. I am also a singer/songwriter, basically a metal vocalist. Started a christian metal band recently as I got a great help from the Talentverse community. As you guys know metal is not acceptable by many, but I was able to perform my original metal songs through Talentverse first time in my life. I have met many musicians through this community. All thanks to Naveen bro who took the initiative to make this community. Thanks bro and thanks to Talentverse :)',
  },
  {
    id: 3,
    image: img5,
    name: 'Nischaya L Shastry',
    title: 'Singer',
    quote: "From day one, Talentverse has treated me with respect and professionalism. They have built a platform where artists are guaranteed to be paid fairly, and the team is always responsive and approachable. Their friendly, yet efficient management style makes them a standout in the industry, and I'm excited to continue working with them.",
  },
  {
    id: 4,
    image: img6,
    name: 'Abhay Rajeev',
    title: 'Singer',
    quote: "I've had a great experience working with Talentverse. As a singer, I look for artist management companies that are fair to the artists. Naveen has been very supportive and respectful to the artists and that's a big plus point. I’m grateful to be part of this amazing network and look forward to many more opportunities to perform through them!",
  },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredButton, setHoveredButton] = useState(null);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const pastIndex = (currentIndex - 1 + testimonials.length) % testimonials.length;
  const nextIndex = (currentIndex + 1) % testimonials.length;

  return (
    <div className="text-white p-4 py-8 sm:p-8 rounded-lg">
      <div className="flex justify-between items-center">

        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center items-center mb-4">
            <button
              onClick={handlePrev}
              className="text-3xl mx-4"
              onMouseEnter={() => setHoveredButton("prev")}
              onMouseLeave={() => setHoveredButton(null)}
            >
              <img
                src={hoveredButton === "prev" ? img2 : img1}
                alt="Previous"
                className="w-8 h-8 scale-x-[-1] rounded-full overflow-hidden"
              />
            </button>
            <div className="w-[48px] sm:w-[90px] h-[48px] sm:h-[90px] rounded-full overflow-hidden mr-2">
              <img
                src={testimonials[pastIndex].image}
                alt={testimonials[pastIndex].name}
                className="object-cover w-[48px] sm:w-[90px] h-[48px] sm:h-[90px] rounded-full overflow-hidden opacity-40"
              />
            </div>
            <div className="w-[80px] sm:w-[150px] h-[80px] sm:h-[150px] rounded-full overflow-hidden mx-2">
              <img
                src={testimonials[currentIndex].image}
                alt={testimonials[currentIndex].name}
                className="object-cover  w-[80px] sm:w-[150px] h-[80px] sm:h-[150px] overflow-hidden rounded-full"
              />
            </div>
            <div className="w-[48px] sm:w-[90px] h-[48px] sm:h-[90px] rounded-full overflow-hidden ml-2">
              <img
                src={testimonials[nextIndex].image}
                alt={testimonials[nextIndex].name}
                className="object-cover w-[48px] sm:w-[90px] h-[48px] sm:h-[90px] rounded-full overflow-hidden opacity-40"
              />
            </div>
            <button
          onClick={handleNext}
          className="text-3xl mx-4"
          onMouseEnter={() => setHoveredButton("next")}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <img
            src={hoveredButton === "next" ? img2 : img1}
            alt="Next"
            className="w-8 h-8 rounded-full "
          />
        </button>
          </div>
          <div className='flex flex-col h-[300px] sm:h-64 items-center sm:max-w-4xl'>
            <p className="text-xl font-medium font-bebas">{testimonials[currentIndex].name}</p>
            <p className="text-sm text-gray-400 font-poppins font-extralight">{testimonials[currentIndex].title}</p>
            <p className="text-[12px] sm:text-[18px] text-center m-4 max-w-lg sm:max-w-xl font-poppins font-extralight">{testimonials[currentIndex].quote}</p>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default TestimonialCarousel;
