import React from "react";
import { BackgroundLines } from "./BackgroundLines";

export function BackgroundLinesDemo() {
  return (
    <BackgroundLines className="flex items-center justify-center w-full flex-col px-4 rounded-[24px] sm:rounded-[56px]">
      <p className="text-white font-normal text-[32px] sm:text-5xl leading-[4rem] tracking-wide p-4 text-center font-bebas">
        <span className="text-[#fe4d58]">BLOGS {" "}</span> AND {" "}<span className="gradient_text"> MEDIA</span>
      </p>
      <p
        className="text-[14px] sm:text-base text-white text-center font-extralight font-['Poppins']">
        Explore the latest insights, stories, and news from the world of talent, creativity, and events.
      </p>
    </BackgroundLines>
  );
}
