import React, { useEffect, useState } from "react"
import FeaturesHeader from "./FeaturesHeader"
import Footer from "./Footer"
import GetInTouch from "./GetInTouch"
import NavbarDemo from "./NavbarDemo"
import Rights from "./Rights"
import Values from "./Values"
import { WobbleCardDemo } from "./WobbleCardDemo"
import ToggleButton from "./ToggleButton"
import { useNavigate } from "react-router-dom"
import img1 from "../assets/Icons 03.svg"
import img2 from "../assets/Icons 02.svg"
import img3 from "../assets/Icons 01.svg"

const BuisnessFeaturesPage = () => {
    const navigate = useNavigate()
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const headerElement = document.getElementById("features-header");

            if (headerElement) {
                const headerHeight = headerElement.offsetHeight;
                setIsSticky(scrollPosition >= headerHeight);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <div className="bg-[#11071A] overflow-hidden">
            <div className="relative opacity-30">
                <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl"></div>
                <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl"></div>
                <div className="absolute opacity-60 top-60 -left-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-60 -right-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <div id="features-header">
                <FeaturesHeader />
            </div>
            <div className={`${isSticky ? "fixed top-0 left-0 right-0 z-40 mt-24" : "relative"
                } flex justify-center `}>
                <ToggleButton />
            </div>
            <WobbleCardDemo t1="Browse artists, book talent, simplify planning with " t2="Talentverse"
                t3="Easily Discover and Explore a Variety of Talented Artists"
                c3="Explore a wide range of talented artists available for for various occasions. Use simple filters to find the perfect match based on category, location, or budget. Whether you need a musician, dancer, or performer, we make it easy to discover and connect with the right talent."
                t4="Simple Booking and Scheduling Process"
                c4="Book artists for your requirements with just a few clicks. Choose the dates and times that fit your schedule, and confirm your bookings quickly. Our platform ensures that you have all the information you need to plan your events seamlessly without any hassle."
                t5="Sync Your Events with Calendar"
                c5="Keep all your important event dates in one place by syncing with your calendar. This feature allows you to easily track your bookings and deadlines, so you never miss an important date. Stay organized and manage your events without stress."
                t6="Effortlessly Manage All Your Invoices and Payments"
                c6="Manage all your invoices for the artists you hire in one place. You can view, pay, or track payments easily. Our invoice management system helps you stay on top of your budget and gives you a clear view of your financial commitments for each project."
                t7="Get Useful Tips for Fair Price Negotiation"
                c7="Use our negotiation feature to find fair prices between you and the artists. Our platform offers helpful suggestions based on your needs and the artist’s requirements. This ensures that both parties are satisfied with the agreed price, making the booking process smoother."
                t8="New Features Coming Soon"
                c8="We are continuously working to improve your experience on Talentverse. Stay tuned for upcoming features that will enhance your ability to discover talent, manage events, and create memorable experiences. We’re committed to bringing you the best tools to make your events successful." />
            <div className="flex flex-col items-center my-16">
                <p className="text-white text-5xl uppercase font-bebas"> explore {" "}<span className="gradient_text">{" "}our services </span> </p>
            </div>
            <div className="relative opacity-40">
                <div className="absolute top-20 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                {/* <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div> */}
            </div>
            <Values  img1={img1} img2={img2} img3={img3} t1="Team Engagement Activities" t2="Memorable Corporate Events" t3="Easy Artist Booking "
                c1="Organize creative workshops, team-building events, health programs, and cultural activities to enhance team spirit and collaboration."
                c2="Plan art and music festivals, award functions, networking meets, and concerts that bring your team together and celebrate achievements."
                c3="Access an exclusive roster of talents, with a simplified booking process and customized entertainment packages for your needs." />
            <div className="flex justify-center mt-10">
                <button
                    onClick={() => navigate("/business/services")}
                    className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-6 rounded-xl p-3 font-light text-white font-poppins"
                >
                    All Services
                </button>
            </div>
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default BuisnessFeaturesPage