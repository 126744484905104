import React, { useState, useEffect } from "react";
import AnotherFeatures from "./AnotherFeatures";
import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import NavbarDemo from "./NavbarDemo";
import Rights from "./Rights";
import ServicesHeader from "./ServicesHeader";
import ToggleButton from "./ToggleButton";
import Values from "./Values";
import { useNavigate } from "react-router-dom";
import img1 from "../assets/Icons 06.svg"
import img2 from "../assets/Icons 05.svg"
import img3 from "../assets/Icons 04.svg"

const ServicesPage = () => {
  const navigate = useNavigate()
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = document.getElementById("services-header").offsetHeight;

      if (scrollPosition >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
 
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-[#11071A] overflow-hidden ">
      <div className="relative opacity-30">
        <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl"></div>
        <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl"></div>
        <div className="absolute opacity-60 top-60 -left-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
        <div className="absolute opacity-60 top-60 -right-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
      </div>
      <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
      <div id="services-header">
        <ServicesHeader texts="Discover a range of services designed to help you grow, manage, and promote your talent across every stage of your career."/>
      </div>
      <div
        className={`${isSticky ? "fixed top-0 left-0 right-0 z-40 mt-24" : "relative mt-20"
          } flex justify-center toggle-button`}
      >
        <ToggleButton />
      </div>
      <div className="relative z-20 top-80">
        <div className="absolute top-96 left-0 w-[300px] h-[300px] bg-[#650000] rounded-3xl mix-blend-screen filter opacity-30 blur-[100px]"></div>
        {/* <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '10vw', top: "100vh" }}></div> */}
        {/* <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '17vw', top: "95vh" }}></div> */}
      </div>
      <div className="pt-10">
        <AnotherFeatures
        src1="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/images%2FFrame%2062.svg?alt=media&token=f77a6b0a-5f2f-4701-bc75-8cd9aab8da24"
                src2="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2Ftemp2%2Fggggg.svg?alt=media&token=8a2efb49-2451-4dc0-9cb6-e0080d77cb06"
                src3="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2Ftemp2%2FFrame%2062.svg?alt=media&token=3e798a8e-18f7-4701-948c-f030c78dbfe7"
                src4="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2Ftemp2%2Fdds.svg?alt=media&token=5117a846-6604-41e5-a19c-05223b54e972"
                src5="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2Ftemp2%2Fasd.svg?alt=media&token=7c8b2d92-7bc7-48db-9031-aea5fc030e73"
         t1="Branding & Online Presence" 
        c1="Build your unique brand and online identity. We help you create an artist website, design logos, and develop merchandise that reflects your style. You can also set up a professional portfolio and manage your social media profiles to connect with fans and showcase your work."
        t2="Content Creation & Production"
        c2="We help with photo and video shoots, creating showreels, and producing high-quality audio and video. If you're a musician or voice actor, we provide studio recording services to capture your best performance, along with music production to enhance your sound and video production to create stunning visuals for your projects."
        t3="Talent Management & Support"
        c3="Get the help you need for your career with our management services. We assist with talent management, contract negotiations, and career coaching. Our team helps you plan your long-term goals, making it easier for you to focus on your art."
        t4="Growth Opportunities"
        c4="Discover new gigs and collaboration options to grow your career. We help you find bookings for live shows, art exhibitions, auditions, and more. Join mentorship programs and e-learning workshops to learn new skills and connect with other artists."
        t5="Marketing & Promotion"
        c5="Boost your visibility with our marketing and promotion services. We offer press coverage and PR support to help you reach a larger audience. You can also explore options for selling merchandise, getting paid endorsements, and collaborating with brands for sponsored content." />
      </div>
      <div className="relative z-20 -top-80">
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#650000] rounded-3xl mix-blend-screen filter opacity-30 blur-[100px]"></div>
        {/* <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '10vw', top: "3vh" }}></div>
         <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl" style={{ left: '17vw', top: "1vh" }}></div> */}
      </div>

      {/* <div className="relative opacity-40">
        <div className="absolute top-10 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
        <div className="absolute top-10 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
        <div className="absolute opacity-60 top-80 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div> 
        <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div> 
         </div> */}
      <div className="flex flex-col items-center my-8 sm:my-16">
        <p className="p-2 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] font-poppins">Our Product</p>
        <p className="text-white text-[32px] sm:text-5xl uppercase font-bebas"> explore <span className="gradient_text">our platform </span>{" "} </p>
      </div>
      <Values img1={img1} img2={img2} img3={img3} t1="Build Your Online Press Kit" t2="Find Transparent Opportunities" t3="Manage Invoices Easily" c1="Create a stunning online portfolio to display your best work. Easily add images, videos, and links to share your artistic journey with clients and collaborators." c2="Search for gigs that are a perfect fit for you with our user-friendly platform. We provide clear details about each opportunity, making it easy to find the right projects that match your skills." c3="Keep track of your earnings and manage invoices easily. Our platform simplifies the invoicing process, allowing you to focus on your art while ensuring you get paid on time." />
      <div className="flex justify-center my-10">
        <button
          onClick={() => navigate("/features")}
          className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] px-6 rounded-xl p-3 font-light text-white font-poppins"
        >
          All Features
        </button>
      </div>
      <GetInTouch />
      <Footer />
      <Rights />
    </div>
  );
};

export default ServicesPage;
