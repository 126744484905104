import { BackgroundLinesDemo } from "./BackgroundLinesDemo"
import Footer from "./Footer"
import GetInTouch from "./GetInTouch"
import Media from "./Media"
import NavbarDemo from "./NavbarDemo"
import Rights from "./Rights"

const BlogsAndMedia = () => {
    return (
        <div className="bg-[#11071A] overflow-hidden">
            <div className="relative opacity-30 z-10">
                <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl pointer-events-none"></div>
                <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl pointer-events-none"></div>
                <div className="absolute opacity-60 top-60 -left-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px] pointer-events-none"></div>
                <div className="absolute opacity-60 top-60 -right-96 w-2/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px] pointer-events-none"></div>
            </div>
            <div className=" flex justify-center ">
            <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-80">
                <NavbarDemo />
            </div>
            </div>
            <div className="p-0.5 bg-gradient-to-r from-[#D76D5E66] via-[#D900FF66] to-[#134FE966] rounded-[24px] sm:rounded-[56px] overflow-hidden mb-10 mt-24 mx-4 sm:mx-32 shadow-2xl shadow-black">
                <div className="h-[15rem] mx-auto -z-10">
                        <BackgroundLinesDemo />
                </div>
            </div>
            <div className="z-20">
            <Media />
            </div>
            <div className="hidden sm:block relative opacity-40 ">
                <div className="absolute top-20 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
            </div>
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default BlogsAndMedia