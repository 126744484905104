import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 

const RichTextEditor = (props) => {
    const handleChange = (content) => {
        props.onChange({ id: props.id, value: content });
    };
    return (
        <div>
            <ReactQuill
                className={`h-[${props.height}px]`}
                onChange={handleChange}
                theme="snow"
                modules={{
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike"], 
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                        ["clean"], 
                    ],
                }}
            />
        </div>
    );
};
export default RichTextEditor;