import { useState } from "react";
import Submit from "./Submit";
import axios from "axios";

const GetInTouch = () => {
    const [formData, setFormData] = useState({type:"getInTouch"});
    const [loading, setLoading] = useState(false)
    const [error , setError ] = useState() ; 
    const [showSubmit, setShowSubmit] = useState(false);


    const handleChange = (e) => {
        setFormData({
            ...formData, [e.target.id]: e.target.value
        })
    }

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(number);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    
    const handleSend = async (e) => {
        e.preventDefault();
        setLoading(true)
        setError(null);
        try {
            if (!formData.name || !formData.email || !formData.contact || !formData.city || !formData.message ){
                 setError("Please fill all the reqired fields") ; 
                 setLoading(false)
                 return ; 
            }
            if (!validateEmail(formData.email)) {
                setError("Invalid email address. Please enter a valid email.");
                setLoading(false);
                return;
            }
            if (!validatePhoneNumber(formData.contact)) {
                setError("Invalid phone number. Please enter a 10-digit number.");
                setLoading(false);
                return;
            }
           const res = await axios.post('https://tv-server-ql7l.onrender.com/api/send-mail' , formData) ; 
           console.log(res.data)
           setFormData({})
            setLoading(false) ; 
            setError(null)
            setShowSubmit(true);
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    const handleCloseSubmit = () => {
        setShowSubmit(false);
    };

    return (
        <div className="flex flex-col items-center h-fit">
            <div className="px-2 my-5 sm:px-6 md:px-24 sm:my-10">
                <div className="gradient-border rounded-[3rem]">
                    <div className="bg-[#11071ad6] rounded-[3rem] text-white p-4 flex flex-col md:flex-row justify-around">
                        <div className="flex flex-col w-full md:w-[50%]">
                            <p className="text-[32px] sm:text-5xl font-medium py-2 px-4 leading-[4rem] tracking-wide font-bebas">LET'S GET IN <span className="gradient_text inline-block">TOUCH</span></p>
                            <p className="p-1 text-[14px] sm:text-[16px] px-4 font-poppins font-extralight">We’d love to hear from you! If you have any questions, ideas, or want to discuss potential partnerships, please fill out the form below, and we’ll reach out to you as soon as possible.</p>
                            <div className="h-64 p-4 mb-10 items-center hidden sm:flex flex-col">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/images%2FGet%20in%20Touch%20.svg?alt=media&token=7b0e32ba-247a-49fe-b8d8-dbf4245c704f"
                                    alt="Contact"
                                    className="rounded-2xl h-60 md:h-[250px]"
                                />
                            </div>
                        </div>
                        <form className="bg-transparent p-1 sm:p-10 mt-4 font-poppins font-extralight w-full md:w-[50%]" >
                            <div className="flex sm:p-2 mb-4 gap-2 sm:gap-4">
                                <div className="gradient-border border border-1 sm:mx-2 w-full md:w-[48%]">
                                    <input value={formData?.name}  onChange={handleChange} type="text" placeholder="Name" id="name" className="bg-[#11071ad6] rounded-xl p-2.5 w-full focus:outline-none" />
                                </div>
                                <div className="gradient-border border border-1 sm:mx-2 w-full md:w-[48%]">
                                    <input value={formData?.email} onChange={handleChange} type="email" placeholder="Email" id="email" className="bg-[#11071ad6] rounded-xl p-2.5 w-full focus:outline-none" />
                                </div>
                            </div>
                            <div className="flex sm:p-2 mb-4 gap-2 sm:gap-4">
                                <div className="gradient-border border border-1 sm:mx-2 w-full md:w-[48%]">
                                    <input value={formData?.contact} onChange={handleChange} type="text" placeholder="Contact" id="contact" className="bg-[#11071ad6] rounded-xl p-2.5 w-full focus:outline-none" />
                                </div>
                                <div className="gradient-border border border-1 sm:mx-2 w-full md:w-[48%]">
                                    <input value={formData?.city} onChange={handleChange} type="text" placeholder="City" id="city" className="bg-[#11071ad6] rounded-xl p-2.5 w-full focus:outline-none" />
                                </div>
                            </div>
                            <div className="p-1 sm:p-2 sm:mx-2 mb-4">
                                <div className="p-0.5 bg-gradient-to-r from-[#D76D5E66]  via-[#6300FF66] to-[#134FE966] rounded-xl">
                                    <div className="w-full bg-[#11071ad6] rounded-xl">
                                        <textarea value={formData?.message} onChange={handleChange} id="message"
                                            rows={5}
                                            placeholder="Help us more with what you are looking for"
                                            className="bg-transparent rounded-xl h-full w-full resize-none overflow-hidden p-2.5 focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 mx-2 font-poppins">
                                <button disabled={loading} onClick={handleSend} className="bg-gradient-to-r from-[#006AFF] to-[#BE00FF] disabled:bg-gray-500 px-6 py-2 rounded-xl w-full md:w-auto">{loading ? "Loading..." : "Submit"}</button>
                            </div>
                            <div>
                                 {error && <p className="text-red-600">{error}</p>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {showSubmit && <Submit onClose={handleCloseSubmit} />}
        </div>
    );
};

export default GetInTouch;
