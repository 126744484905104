import React from "react";
import img2 from "../assets/Frame 82.png";
import img5 from "../assets/thanks.png";

const Submit = ({ onClose }) => {
    return (
        <div className="fixed inset-0 px-[16px] sm:px-0 flex justify-center items-center bg-black bg-opacity-60 z-50">
            <div className="bg-gradient-to-tl from-[#873795CC] via-[#873795CC] to-[#D76D5ECC] text-white font-poppins max-w-5xl mx-auto rounded-2xl overflow-hidden">
                <div className="flex sm:flex-row flex-col items-center bg-[#00000099]">
                    <div className="p-4 sm:py-10 sm:w-[40%]">
                        <img src={img5} className="h-[200px] sm:h-full" alt="" />
                    </div>
                    <div className="sm:py-10 sm:w-[60%]">
                        <p className="sm:p-4 text-[18px] px-4 sm:text-2xl">Thanks for Reaching Out!</p>
                        <p className="px-4 p-2 mr-4 text-[14px] sm:font-base font-extralight">
                            Your request has been submitted successfully. Our team will review your details and get back to you soon with artist recommendations. Keep an eye on your inbox for updates!
                        </p>
                        <div className="hidden">
                            <p className="text-[14px] sm:text-lg font-medium p-2 px-4">Download the app to unlock all features and enjoy a seamless experience on Talentverse!</p>
                            <div className="flex h-32 p-2 justify-center">
                                <img src={img2} alt="" style={{ zIndex: 1 }} />
                                <img src={img2} alt="" className="ml-10" />
                            </div>
                        </div>
                        <button onClick={onClose} className="sm:mt-5 border border-1 border-[#FFFFFF40] text-white px-6 py-2 rounded-xl font-poppins font-extralight m-4">
                            Close
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Submit;
