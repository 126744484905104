import { HiMenuAlt3 } from "react-icons/hi";
import { NavButtons } from "../constants/NavButtons";
import { useNavigate } from "react-router-dom";

const NavbarRes = ({ menuOpen, setMenuOpen }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`sm:hidden fixed top-0 right-0 h-screen text-white shadow-lg w-60 z-50 transform ${
                menuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
            style={{
                backdropFilter: "blur(200px)",
                backgroundColor: "rgba(35, 24, 43, 0.8)",
            }}
        >
            <div className="flex justify-end p-1 mt-2 mr-1">
                <HiMenuAlt3
                    className="menu-icon p-2 h-[40px] w-[40px] cursor-pointer"
                    onClick={() => setMenuOpen(false)}
                />
            </div>
            {NavButtons.map((button, index) => (
                <button
                    key={index}
                    className="w-full text-left px-4 py-2 hover:bg-[#333]"
                    onClick={() => {
                        navigate(button.link);
                        setMenuOpen(false);
                    }} 
                >
                    {button.title}
                </button>
            ))}
        </div>
    );
};

export default NavbarRes;
