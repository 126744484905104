import React from "react";
import { blogsData } from "../constants/MediaData";
import { Link, useLocation } from "react-router-dom";
import NavbarDemo from "./NavbarDemo";

export const Blog = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blogId = queryParams.get("id");
  const blogPost = blogsData[blogId];

  // Filter out the current blog from suggestions
  const suggestedBlogs = blogsData.filter((blog, index) => index !== blogId);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="flex flex-col items-center bg-gray-50 min-h-screen font-poppins">
      <div className=" flex justify-center ">
        <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
          <NavbarDemo />
        </div>
      </div>
      <div
        className="w-full h-96 bg-cover bg-center"
        style={{
          backgroundImage: `url(${blogPost.img})`,
        }}
      ></div>
      <div className=" w-[90%] bg-white rounded-lg shadow-lg -mt-32 p-8 min-h-screen">
        <div className="text-sm text-gray-600 uppercase font-bold">
          {blogPost.category} • {blogPost.timeToRead || "5 min read"}
        </div>
        <h1 className="text-4xl font-bold mt-4 font-bebas leading-[3rem] tracking-wide">{blogPost.heading}</h1>
        {/* <div className="flex items-center space-x-4 mt-4">
          {[
            { platform: "Facebook", icon: "🌐" },
            { platform: "Twitter", icon: "🐦" },
            { platform: "LinkedIn", icon: "🔗" },
            { platform: "Instagram", icon: "📸" },
          ].map((media, index) => (
            <a
              key={index}
              href="#"
              className="text-gray-600 hover:text-blue-500"
              title={media.platform}
            >
              {media.icon}
            </a>
          ))}
        </div> */}
        <div className="container mx-auto p-2 md:p-6 sm:text-justify">
          <nav className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Table of Contents</h2>
            <ul className="pl-2">
              <li>
                <a
                  href="#introduction"
                  className="text-blue-500 hover:underline"
                >
                  1. Introduction
                </a>
              </li>
              <li>
                <a
                  href="#understanding-landscape"
                  className="text-blue-500 hover:underline"
                >
                  2. Understanding the Independent Artist Landscape
                </a>
              </li>
              <li>
                <a
                  href="#creating-business-plan"
                  className="text-blue-500 hover:underline"
                >
                  3. Creating Your Independent Artist Business Plan
                </a>
              </li>
              <li>
                <a
                  href="#building-online-presence"
                  className="text-blue-500 hover:underline"
                >
                  4. Building Your Online Presence
                </a>
              </li>
              <li>
                <a href="#marketing" className="text-blue-500 hover:underline">
                  5. Marketing for Independent Artists
                </a>
              </li>
              <li>
                <a
                  href="#finding-gigs"
                  className="text-blue-500 hover:underline"
                >
                  6. Finding Gigs and Opportunities
                </a>
              </li>
              <li>
                <a
                  href="#monetizing-art"
                  className="text-blue-500 hover:underline"
                >
                  7. Monetizing Your Art
                </a>
              </li>
              <li>
                <a href="#networking" className="text-blue-500 hover:underline">
                  8. Networking for Independent Artists
                </a>
              </li>
              <li>
                <a href="#conclusion" className="text-blue-500 hover:underline">
                  9. Conclusion
                </a>
              </li>
            </ul>
          </nav>

          {/* Content */}
          <section id="introduction" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
            <p>
              Starting your career as an independent artist in 2025 can be both
              exciting and daunting. With the rise of digital platforms and the
              ever-evolving art landscape, there are numerous opportunities to
              explore. This guide to starting your career as an independent
              artist in 2025 will provide you with essential tips and strategies
              to navigate this journey successfully. Whether you are a musician,
              visual artist, or any other type of creative, this guide will help
              you understand how to be an independent artist in today’s world.
            </p>
          </section>

          <section id="understanding-landscape" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Understanding the Independent Artist Landscape
            </h2>
            <p>
              Before diving into the practical steps, it’s crucial to understand
              the current landscape for independent artists. The rise of social
              media and online platforms has transformed how artists connect
              with their audience. Here are some key points to consider:
            </p>
            <ul className="list-disc pl-6">
              <li>
                Diverse Opportunities: From music distribution for independent
                artists to online galleries for visual art, the options are
                vast.
              </li>
              <li>
                Increased Competition: With more artists entering the scene,
                standing out is essential.
              </li>
              <li>
                Changing Consumer Behavior: Audiences are increasingly looking
                for authentic connections with artists.
              </li>
            </ul>
          </section>

          <section id="creating-business-plan" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Creating Your Independent Artist Business Plan
            </h2>
            <p>
              A solid business plan is the foundation of your career as an
              independent artist. Here’s how to create one:
            </p>
            <ol className="list-decimal pl-6">
              <li>
                <strong>Define Your Brand</strong>: What makes you unique?
                Create a clear artist brand that reflects your style and vision.
              </li>
              <li>
                <strong>Set Goals</strong>: Outline short-term and long-term
                goals for your career. This could include releasing an album,
                showcasing your art in a gallery, or building a specific number
                of followers on social media.
              </li>
              <li>
                <strong>Budgeting</strong>: Determine your startup costs,
                including materials, marketing, and distribution. Consider how
                to make money as an independent artist through various revenue
                streams.
              </li>
            </ol>
          </section>

          <section id="building-online-presence" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Building Your Online Presence
            </h2>
            <p>
              In today’s digital age, building an online presence is crucial for
              independent artists. Here are some steps to get started:
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Create a Website</strong>: Your website should showcase
                your portfolio, provide information about your work, and include
                a blog to share updates and insights.
              </li>
              <li>
                <strong>Utilize Social Media</strong>: Platforms like Instagram,
                TikTok, and Facebook are excellent for promoting yourself as an
                artist. Share behind-the-scenes content, engage with your
                audience, and showcase your work.
              </li>
              <li>
                <strong>Join Online Platforms for Artists</strong>: Websites
                like Bandcamp for musicians or ArtStation for visual artists can
                help you reach a broader audience.
              </li>
            </ul>
          </section>

          <section id="marketing" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Marketing for Independent Artists
            </h2>
            <p>
              Effective marketing is key to launching an art career. Here are
              some strategies to consider:
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Content Marketing</strong>: Create valuable content that
                resonates with your audience. This could be tutorials, blog
                posts, or videos related to your art.
              </li>
              <li>
                <strong>Email Marketing</strong>: Build an email list to keep
                your fans updated on new releases, exhibitions, or events.
              </li>
              <li>
                <strong>Collaborations</strong>: Partner with other artists or
                brands to expand your reach and tap into new audiences.
              </li>
            </ul>
          </section>

          <section id="finding-gigs" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Finding Gigs and Opportunities
            </h2>
            <p>
              Finding gigs is essential for building a career in independent
              music or any art form. Here are some tips:
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Local Venues</strong>: Start by performing at local
                venues or art shows. This helps you gain experience and build a
                local following.
              </li>
              <li>
                <strong>Online Platforms</strong>: Use platforms like GigSalad
                or ReverbNation to find performance opportunities.
              </li>
              <li>
                <strong>Networking</strong>: Attend industry events, workshops,
                and meetups to connect with other artists and potential
                collaborators.
              </li>
            </ul>
          </section>

          <section id="monetizing-art" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Monetizing Your Art</h2>
            <p>
              Making money as an independent artist requires creativity and
              strategy. Here are some ways to monetize your work:
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Sell Your Art</strong>: Use online marketplaces like
                Etsy or your website to sell your artwork or music.
              </li>
              <li>
                <strong>Crowdfunding</strong>: Platforms like Patreon allow fans
                to support you directly in exchange for exclusive content.
              </li>
              <li>
                <strong>Merchandising</strong>: Create merchandise related to
                your art, such as prints, apparel, or digital downloads.
              </li>
            </ul>
          </section>

          <section id="networking" className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">
              Networking for Independent Artists
            </h2>
            <p>
              Networking is vital for career development. Here are some
              effective strategies:
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Join Artist Communities</strong>: Engage with local and
                online artist communities to share experiences and
                opportunities.
              </li>
              <li>
                <strong>Attend Workshops and Conferences</strong>: These events
                are great for learning and meeting industry professionals.
              </li>
              <li>
                <strong>Utilize Social Media</strong>: Connect with other
                artists and industry professionals on platforms like LinkedIn
                and Instagram.
              </li>
            </ul>
          </section>

          <section id="conclusion">
            <h2 className="text-2xl font-semibold mb-2">Conclusion</h2>
            <p>
              Starting your career as an independent artist in 2025 is an
              exciting journey filled with opportunities. By understanding the
              landscape, creating a solid business plan, building your online
              presence, and effectively marketing your work, you can
              successfully launch your art career. Remember to network and seek
              out opportunities to grow and monetize your art. Embrace the
              journey, and don’t hesitate to reach out for support along the
              way.
            </p>
          </section>
        </div>
      </div>
      <div>
        {suggestedBlogs.length > 0 && (
          <div className="p-4 m-4 sm:m-8 sm:p-10">
            <h2 className="text-black text-lg font-semibold mb-4">You might also like</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
              {suggestedBlogs.map((blog, index) => {
                if (index !== Number(blogId)) { // Convert blogId to a number for comparison
                  return (
                    <Link to={`/blog?id=${index}`} key={index}>
                      <div className="bg-gradient-to-r from-transparent to-transparent hover:from-[#134FE980] hover:via-[#6300FF80] hover:to-[#D76D5E80] p-[1px] rounded-[25px] hover:shadow-[0_0_20px_rgba(215,109,94,0.38),_0_0_20px_rgba(19,79,233,0.4)]">
                        <div className="rounded-[25px]">
                          <div className="bg-transparent hover:bg-[#1B1B1B80] rounded-[25px] flex flex-col items-center p-4">
                            <img
                              src={blog.img}
                              alt={blog.heading}
                              className="w-full h-[250px] rounded-[16px] object-cover"
                            />
                            <span className="px-1 w-full">
                              <p className="py-2font-semibold text-[16px] sm:text-lg w-full">
                                {blog.heading}
                              </p>
                              <p className=" text-[12px] sm:text-[14px] tracking-wide font-poppins font-extralight text-justify">
                                {blog.description.slice(0, 140) + "..."}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                return null; // Explicitly return null if the condition isn't met
              })}

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
