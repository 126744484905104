import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ToggleButton = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState(true);
    
    useEffect(() => {
        if (location.pathname.startsWith("/business")) {
            setSelected(false);
        } else {
            setSelected(true);
        }
    }, [location.pathname]);

    const handleNavigation = (isArtistSelected) => {
        const basePath = location.pathname.replace(/^\/business/, "").replace(/^\//, "");
        const targetPath = isArtistSelected ? `/${basePath}` : `/business/${basePath}`;
        navigate(targetPath);
    };

    return (
        <div className="relative font-bebas">
            <div className="w-fit bg-gradient-to-r from-[#873795] to-blue-700 p-0.5 rounded-full">
                <div className="bg-black rounded-full">
                    <button
                        onClick={() => {
                            setSelected(true);
                            handleNavigation(true);
                        }}
                        className={`p-2 px-8 text-white font-medium transition-all duration-300 rounded-full ${selected ? "bg-gradient-to-r from-[#006AFF] to-[#BE00FF]" : "bg-black text-[#4E4E4E]"}`}>
                        ARTIST
                    </button>

                    <button
                        onClick={() => {
                            setSelected(false);
                            handleNavigation(false);
                        }}
                        className={`p-2 px-8 text-white transition-all font-medium duration-300 rounded-full ${!selected ? "bg-gradient-to-r from-[#6A94FF] to-[#6300FF]" : "bg-black text-[#4E4E4E]"}`}>
                        OTHERS
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ToggleButton;
