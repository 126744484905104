import { useNavigate, useLocation } from "react-router-dom";
import img from "../assets/hehe.png";
import { NavButtons } from "../constants/NavButtons";
import { useState, useEffect } from "react";
import img1 from "../assets/Logo Side.svg";
import { HiMenuAlt3 } from "react-icons/hi";
import img3 from "../assets/Logo Icon Seperate.svg"
import NavbarRes from "./NavbarRes";

const NavbarDemo = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const isActive = (path) => location.pathname === path;

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest(".menu-modal") && !event.target.closest(".menu-icon")) {
                setMenuOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <div className="sticky top-4 rounded-2xl flex justify-between mx-4 sm:mx-0 text-white h-16 bg-[#23182BB2] font-poppins px-4 sm:px-0">
            <div className="hidden sm:flex items-center ml-6">
                <button onClick={() => navigate("/")}>
                    <img src={img1} alt="logo" className="h-[50px] mr-2" />
                </button>
            </div>
            <div className="sm:hidden flex items-center">
                <button onClick={() => navigate("/")}>
                    <img src={img3} alt="logo" className="h-[30px] mr-2" />
                </button>
            </div>
            <div className="flex gap-1">
                <div className="sm:hidden flex items-center mr-2 rounded-[12px] h-fit my-auto p-[1px]">
                    <div className="text-sm font-medium bg-black rounded-[12px]">
                        <button className="px-3 p-2 card text-[13px]" onClick={() => navigate("/enquiryform")}>
                            Book Now
                        </button>
                    </div>
                </div>
                <div className="sm:hidden flex items-center">
                    <HiMenuAlt3
                        className="menu-icon py-2 h-[40px] w-fit cursor-pointer"
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                </div> 
            </div>
            {/* <div className="backdrop-blur-md bg-opacity-100">
            <NavbarRes menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </div> */}
                        <NavbarRes menuOpen={menuOpen} setMenuOpen={setMenuOpen} />


            <div className="hidden sm:flex gap-[79px]">
                {NavButtons.map((button, index) => (
                    <button
                        key={index}
                        className={`relative ${isActive(button.link) ? "gradient_text" : ""} hover:gradient_text`}
                        onClick={() => navigate(button.link)}
                        onMouseEnter={() => setHoveredIndex(index)} 
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        {button.title}
                        {(isActive(button.link) || hoveredIndex === index) && (
                            <img src={img} alt="underline" className="absolute left-0 bottom-0 w-96" />
                        )}
                    </button>
                ))}
            </div>

            <div className="hidden sm:flex items-center mr-6 rounded-[12px] h-fit my-auto p-[1px]">
                <div className="text-sm font-medium bg-black rounded-[12px]">
                    <button className="px-5 p-2 card" onClick={() => navigate("/enquiryform")}>
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NavbarDemo;