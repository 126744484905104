import React from "react"
import { FocusCards } from "./focus-cards";
import img from "../assets/TV4.svg"
import img1 from "../assets/TV1.svg"
import img2 from "../assets/TV2.svg"
import img3 from "../assets/TV3.svg"
import img4 from "../assets/TV5.svg"
import img5 from "../assets/TV6.svg"

export function FocusCardsDemo() {
  const cards = [
    {
      title: "World Music Day",
      src: img3,
      borderGradient: "to right, #FE4D581A, #B95DEA1A",
      hoverGradient: "to right, #FE4D5880, #B95DEA80",
      customWidth: "470px",
      customHeight: "235px",
    },
    {
      title: "Flow art jam",
      src: img1,
      borderGradient: "to right, #FE4D5833, #B95DEA33",
      hoverGradient: "to right, #FE4D5880, #B95DEA80",
      customWidth: "262px",
      customHeight: "235px",
    },
    {
      title: "Beatbox Championship",
      src:img4 ,
      borderGradient: "to right, #FE4D5833, #B95DEA33",
      hoverGradient: "to right, #FE4D5880, #B95DEA80",
      customWidth: "407px",
      customHeight: "235px",
    },
    {
      title: "Dance Cypher",
      src: img,
      borderGradient: "to right, #4DE9FE33, #C5EA5D33",
      hoverGradient: "to right, #4DE9FE80, #C5EA5D80",
      customWidth: "268px",
      customHeight: "235px",
    },
    {
      title: "Crazy CL promotion",
      src: img5,
      borderGradient: "to right, #16B9FF33, #B95DEA33",
      hoverGradient: "to right,  #16B9FF80, #B95DEA80",
      customWidth: "473px",
      customHeight: "235px",
    },
    {
      title: "Largest Rock MOb",
      src: img2,
      borderGradient: "to right, #FE4D5833, #B95DEA33",
      hoverGradient: "to right, #FE4D5880, #B95DEA80",
      customWidth: "398px",
      customHeight: "235px",
    },
  ];

  return <FocusCards cards={cards} />;
}
