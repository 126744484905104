import img from "../assets/RodSteiger.jpg"
import img2 from "../assets/AbrahamLincoln.jpg"
import img3 from "../assets/EdgarDegas.jpg"

export const Quotes = [
  {
    id: 1,
    quote: "Art enables us to find ourselves and lose ourselves at the same time.",
    author: "Thomas Merton",
    image: img
  },
  {
    id: 2,
    quote: "Art is not what you see, but what you make others see.",
    author: "Edgar Degas",
    image: img2
  },
  {
    id: 3,
    quote: "Creativity takes courage.",
    author: "Henri Matisse",
    image: img3
  }
];
