import { FocusCardsDemo } from "./EventCards"
import OneHeading from "./OneHeading"
import Preposition from "./Preposition"
import Features from "./Features"
import Testimonials from "./Testimonials"
import NavbarDemo from "./NavbarDemo"
import HeroSection from "./HeroSection"
import GetInTouch from "./GetInTouch"
import Footer from "./Footer"
import Rights from "./Rights"
import Banner from "./Banner" 
import FloatingKeys from "./FloatingKeys"
import { FocusRes } from "./focus-cards-res"
import FloatingKeysRes from "./FloatingKeysRes"
import img from "../assets/Artist Side.svg"

const Home = () => {
    return (
        <div className="bg-[#11071A] overflow-hidden">
            <div className="relative ">
            <div className="absolute top-[500px] -left-56 sm:-top-10 sm:-right-36 w-2/5 opacity-60 h-[460px] bg-purple-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
            <div className="absolute top-[500px] -left-56 sm:top-72 sm:-right-36 w-2/5 opacity-60 h-[500px] bg-blue-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
                <div className="absolute sm:-top-10 top-72 -right-0 sm:-right-36 w-2/5 h-[460px] bg-purple-900 rounded-full mix-blend-screen filter opacity-60 blur-[100px] z-30 pointer-events-none"></div>
                <div className="absolute top-48 -right-10 sm:top-72 sm:-right-36 w-2/5 opacity-60 h-[500px] bg-blue-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
            </div>
            <div className=" flex justify-center mx-4 ">
                <div className="fixed top-4 w-full mx-4 sm:mx-0 sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <HeroSection src={img} t1="A one stop calling for all artists!" c1="Build your online portfolio, find transparent gigs, collaborate with fellow artists, and grow with Talentverse."/>
            <Banner />
            <OneHeading />
            <div className="hidden sm:block">
            <FloatingKeys bg="#11071A"/>
            </div>
            <div className="sm:hidden">
                <FloatingKeysRes bg="#11071A"/>
            </div>
            <div className="">
                <Features />
            </div>
            <div className="relative opacity-50">
                <div className="absolute top-20 left-0 w-1/5 h-[100px] sm:h-[400px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[100px] sm:h-[400px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[100px] sm:w-[300px] h-[100px] sm:h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 right-0 w-[100px] sm:w-[300px] h-[100px] sm:h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <div  className={"hidden sm:block"}>
            <FocusCardsDemo/>
            </div>
            <div  className={"sm:hidden"}>
            <FocusRes/>
            </div>
            <div className="relative opacity-40 top-40">
                <div className="absolute top-10 left-0 w-1/5 h-[100px] sm:h-[400px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-10 right-0 w-1/5 h-[100px] sm:h-[400px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 -left-28  w-[100px] sm:w-[400px] h-[100px] sm:h-[400px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 -right-28  w-[100px] sm:w-[400px] h-[100px] sm:h-[400px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <Preposition />
            <Testimonials t1="TESTIMONIALS THAT" t2="TELL" s1="OUR STORY" />
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default Home