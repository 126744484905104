export const NavButtons = [
    {
        title: "About us",
        link: "/about"
    }, 
    {
        title: "Services ",
        link: "/services"
    },
    {
        title: "Platform ",
        link: "/features"
    },
    
    {
        title: "Blogs and Media",
        link: "/blogs"
    }
]