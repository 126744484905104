import { useState } from "react";
import { cn } from "../lib/util";
import TypewriterButton from "./TypewriterEffect";

const FloatingKeysRes = ({ bg }) => {
    const [hovered, setHovered] = useState(null);

    const texts = [
        {
            title: "Dapo Artist",
            position: { top: "133px", right: "-2vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },

        {
            title: "Poi Artist",
            position: { top: "90px", left: "16vw" },
            style: {
                textColor: "white",
                opacity: 0.15,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Musicians",
            position: { top: "15px", left: "-1vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Collaboration Opportunities",
            position: { top: "14px", right: "26vw" },
            style: {
                textColor: "white",
                opacity: 0.15,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Tattoo Artist",
            position: { top: "100px", right: "6vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Didgeridoo",
            position: { top: "320px", right: "4vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Dancers",
            position: { top: "38px", left: "45vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Painters",
            position: { top: "14px", right: "-2vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Writers",
            position: { top: "200px", left: "40vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Singers",
            position: { top: "85px", left: "42vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Caricature Artist",
            position: { top: "60px", right: "3vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Press Kits",
            position: { top: "300px", left: "18vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Photographers",
            position: { top: "130px", left: "-2vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Videographers",
            position: { top: "270px", left: "35vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Actors",
            position: { top: "277px", right: "15vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Theatre Artists",
            position: { top: "230px", left: "0vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Graphic Designers",
            position: { top: "125px", left: "30vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Wedding Gigs",
            position: { top: "194px", left: "2vw" },
            style: {
                textColor: "white",
                opacity: 0.45,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Rappers",
            position: { top: "290px", left: "-2vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Chefs",
            position: { top: "250px", left: "28vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Jams Sessions",
            position: { top: "250px", right: "4vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Artist Managers",
            position: { top: "197px", right: "1vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Music Producers",
            position: { top: "50px", left: "10vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
    ];


    return (
        <div className="h-[420px] relative">
            {texts.map((text, index) => (
                <div
                    key={text.title}
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                    className={cn(
                        "absolute transition duration-1000 ease-in-out rounded-lg",
                        hovered !== null && hovered !== index && ""
                    )}
                    style={{
                        top: text.position.top,
                        bottom: text.position.bottom,
                        right: text.position.right,
                        left: text.position.left,
                        padding: '1px',
                        background: hovered === index
                            ? `linear-gradient(${text.hoverGradient}) `
                            : '#FFFFFF33',

                        ...text.style,
                    }}
                >
                    <div
                        className=" rounded-lg transition-all duration-[2000ms] delay-[200ms] ease-in-out"
                        style={{ backgroundColor: bg }}
                    >
                        <div
                            className={cn(
                                "text-white text-[0.6rem] px-[0.5rem] py-[0.3rem] rounded-lg",
                                hovered === index
                                    ? "text-xl gradient-text"
                                    : "text-[12px]"
                            )}
                            style={{
                                transition: "all 0.5s ease-in-out",
                            }}
                        >
                            <p>{text.title}</p>
                        </div>
                    </div>
                </div>
            ))}

            <div className="absolute -top-[18%] inset-0 z-20 pointer-events-none">
                <TypewriterButton bg={bg} />
            </div>
        </div>
    );
};


export default FloatingKeysRes