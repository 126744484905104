import { IoCallOutline } from "react-icons/io5";
import { TbBrandWhatsapp } from "react-icons/tb";
    
const PageIcons = () => {
    const handleClickWithDelay = (url) => {
        setTimeout(() => {
            window.location.href = url;
        }, 100); 
    };

    return (
        <div>
            <div className="fixed left-0 top-[70%] transform -translate-y-1/2 space-y-4 z-20">
                <div className="flex items-center group bg-gradient-to-r from-[#006AFF] to-[#BE00FF] rounded-e-2xl p-2 text-white text-xl font-semibold shadow-lg">
                    <button
                        onClick={() => handleClickWithDelay("https://wa.me/8618896112")}
                        className="p-2"
                    >
                        <TbBrandWhatsapp />
                    </button>
                </div>
            </div>
            <div className="fixed left-0 top-[79%] transform -translate-y-1/2 space-y-4 z-20">
                <div className="flex items-center group bg-gradient-to-r from-[#006AFF] to-[#BE00FF] shadow-lg rounded-e-2xl p-2 text-white text-xl font-semibold">
                    <button
                        onClick={() => handleClickWithDelay("tel:+918618896112")}
                        className="p-2"
                    >
                        <IoCallOutline />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PageIcons;