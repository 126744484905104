const Values = ({img1,img2,img3,t1,t2,t3,c1,c2,c3}) => {
    return(
        <div className="flex flex-col items-center">
           
            <div className="grid sm:grid-cols-3 text-white px-4 sm:px-24 gap-6 sm:pb-10">
                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img1} alt="" className="" />
                    </div>
                    <p className="font-poppins font-medium py-2">{t1} </p>
                    <p className="font-poppins font-light text-[#F5E1FF] opacity-80 text-justify text-[14px] ">{c1}</p>
                </div>
                <div>
                    <div className=" rounded-xl w-fit ">
                    <img src={img2} alt="" className="" />
                    </div>
                    <p className="font-poppins font-medium py-2">{t2}</p>
                    <p className="font-poppins font-light text-[#F5E1FF] opacity-80 text-justify text-[14px] ">{c2}</p>
                </div>
                <div>
                <div className=" w-fit rounded-xl">
                    <img src={img3} alt="" className="" />
                    </div>
                    <p className="font-poppins font-medium py-2">{t3}</p>
                    <p className="font-poppins font-light text-[#F5E1FF] opacity-80 text-justify text-[14px] ">{c3}</p>
                </div>
                </div>
        </div>
    )
}

export default Values