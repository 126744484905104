import React from 'react';

const Banner = () => {
  return (
    <div className="overflow-hidden py-2 sm:py-4 bg-gradient-to-r from-[#1B2D5ACC] via-[#2F007BCC] to-[#3D1A43CC]">
    <div className="whitespace-nowrap animate-marquee">
      <h1 className="text-[16px] sm:text-2xl font-semibold text-yellow-400 inline-block mx-8">
      We set the India Book of Record for the largest rock mob on Independence Day, featuring 120+ artists, in collaboration with Lulu Mall.
      </h1>
    </div>
  </div>
  );
};

export default Banner;
