import { useState } from "react";
import { cn } from "../lib/util";
import TypewriterButton from "./TypewriterEffect";

const FloatingKeys = ({bg}) => {
    const [hovered, setHovered] = useState(null);

    const texts = [
        {
            title: "Dapo Artist",
            position: { top: "19vh", left: "28vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },

        {
            title: "Poi Artist",
            position: { top: "20vh", left: "46vw" },
            style: {
                textColor: "white",
                opacity: 0.15,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Musicians",
            position: { top: "14vh", left: "23vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Collaboration Opportunities",
            position: { top: "17vh", right: "16vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Tattoo Artist",
            position: { top: "27vh", right: "16vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Didgeridoo",
            position: { bottom: "52vh", right: "21vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Dancers",
            position: { top: "19vh", left: "39vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Film Makers",
            position: { top: "38vh", left: "46vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Painters",
            position: { top: "12vh", left: "51vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Private Shows",
            position: { top: "47vh", left: "33vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Writers",
            position: { bottom: "31vh", left: "40vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Singers",
            position: { top: "28vh", left: "23vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Caricature Artist",
            position: { top: "14vh", right: "33vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Press Kits",
            position: { bottom: "14vh", right: "46vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Flow Artist",
            position: { bottom: "23vh", right: "40vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "DJs",
            position: { top: "38vh", right: "25vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Standup Comedians",
            position: { top: "31vh", right: "25vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Photographers",
            position: { top: "36vh", left: "10vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Events",
            position: { top: "51vh", left: "15vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Videographers",
            position: { bottom: "16vh", left: "35vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Actors",
            position: { bottom: "41vh", right: "15vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Theatre Artists",
            position: { bottom: "31vh", left: "10vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Graphic Designers",
            position: { top: "31vh", left: "40vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Quality Talents",
            position: { bottom: "43vh", right: "34vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Wedding Gigs",
            position: { bottom: "53vh", left: "22vw" },
            style: {
                textColor: "white",
                opacity: 0.45,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Networking Oppurtunities",
            position: { bottom: "37vh", left: "18vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Rappers",
            position: { bottom: "26vh", left: "30vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Corporate Shows",
            position: { top: "27vh", left: "50vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Chefs",
            position: { bottom: "38vh", right: "45vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Jams Sessions",
            position: { bottom: "37vh", right: "26vw" },
            style: {
                textColor: "white",
                opacity: 0.35,
                filter: "blur(1px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Artist Managers",
            position: { top: "42vh", right: "8vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Transparent Oppurtunities",
            position: { bottom: "22vh", left: "20vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Mandala Artist",
            position: { bottom: "25vh", right: "15vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Online Portfolio",
            position: { bottom: "22vh", right: "29vw" },
            style: {
                textColor: "white",
                opacity: 0.25,
                filter: "blur(2px)",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Beat Boxers",
            position: { bottom: "17vh", right: "13vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
        {
            title: "Music Producers",
            position: { bottom: "19vh", right: "21vw" },
            style: {
                textColor: "white",
            },
            hoverGradient: "to right, #FE4D58, #4277FF, #6D11FF",
        },
    ];

    return (
        <div className="h-screen relative">
            {texts.map((text, index) => (
                <div
                key={text.title}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
                className={cn(
                    "absolute transition-all duration-[2000ms] ease-in-out rounded-lg",
                    hovered !== null && hovered !== index && ""
                )}
                style={{
                    top: text.position.top,
                    bottom: text.position.bottom,
                    right: text.position.right,
                    left: text.position.left,
                    padding: '1px',
                    background: hovered === index
                        ? `linear-gradient(${text.hoverGradient})`
                        : '#FFFFFF33',
            
                    ...text.style,
                }}
            >
                <div
                    className=" rounded-lg transition-all duration-[2000ms] delay-[200ms] ease-in-out"
                    style={{ backgroundColor: bg }}
                >
                    <div
                        className={cn(
                            "text-white text-sm px-3 py-2 rounded-lg",
                            hovered === index
                                ? "text-xl gradient-text"
                                : "text-sm"
                        )}
                        style={{
                            transition: "all 0.5s ease-in-out",
                        }}
                    >
                        <p>{text.title}</p>
                    </div>
                </div>           
                </div>
            ))}
            {/* <TypewriterButton/> */}
            <div className="absolute inset-0 z-20 pointer-events-none">
                <TypewriterButton bg={bg}/>
            </div>
        </div>
    );
};


export default FloatingKeys