import React from "react";
import { MeteorsDemo } from "./MeteorsDemo";

const BuisnessPreposition = () => {
  return (
    <div className="w-full flex flex-col items-center pb-10">
      <div className="flex flex-col items-center my-8 sm:my-16">
        <p className="pt-2 text-white text-[14px] sm:text-base">Value Preposition</p>
        <p className="text-white leading-[4rem] tracking-wide font-normal text-[32px] sm:text-5xl p-3 font-bebas">
          WHY {" "}
          <span className="gradient_text inline-block">
            TALENTVERSE 
          </span>
          {" "} ?
        </p>
      </div>
      <div className="grid md:grid-cols-3 gap-10 mb-10">
        <MeteorsDemo
          title="Vast Range of Talents"
          description="Find a diverse range of artists, from musicians to visual creators, all in one place. Talentverse connects you with exceptional talents that elevate any event, ensuring your occasion is memorable."
        />
        <MeteorsDemo
          title="Effortless Booking Process"
          description="Our intuitive platform allows you to find and book artists seamlessly. With just a few clicks, you can secure top talent for your event, saving you time and effort."
        />
        <MeteorsDemo
          title="Transparent Pricing"
          description="At Talentverse, we believe in clarity. Our straightforward pricing model ensures no hidden fees, giving you complete peace of mind as you plan your event."
        />
      </div>
      <div className="grid md:grid-cols-2 gap-10 mb-10">
        <MeteorsDemo
          title="Quality Screened Artists"
          description="We ensure that all artists on our platform are thoroughly vetted for professionalism and quality. Book with peace of mind, knowing that your event will be in capable hands."
        />
        <MeteorsDemo
          title="Customized Packages"
          description="Tailor your event experience with our flexible packages designed to meet your unique needs. Talentverse offers options that cater to various event types, ensuring an unforgettable experience for your guests."
        />
      </div>
    </div>
  );
};

export default BuisnessPreposition;
