const OneHeading = () => {
    return (
        <div className="mt-8 sm:mt-16 mb-5 sm:mb-0">
            {/* <p className="pt-2 text-center text-[14px] sm:text-[18px] text-[#F5E1FF] opacity-80 ">Services</p> */}
            <p className="text-white font-normal text-center sm:leading-[4rem] tracking-wide text-[32px] sm:text-5xl pt-3 font-bebas">
                WITH {" "}
                <span className="gradient_text">
                    TALENTVERSE
                </span>
                {" "} YOU CAN DISCOVER
            </p>
            <div className="relative opacity-40">
            <div className="absolute top-10 -left-10 w-1/5 h-[400px] bg-[#9E00FF] rounded-full mix-blend-screen filter opacity-60 blur-[110px]"></div>
            <div className="absolute top-64 -right-10 w-1/5 h-[400px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-50 blur-[110px]"></div>
            </div>
        </div>
    )
}

export default OneHeading
