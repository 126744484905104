import React from "react";
import img from "../assets/FirstWala.svg";
import { WobbleCard } from "./Wobble-card";

export function WobbleCardDemo({ t1, t2, t3, c3, t4, c4, t5, c5, t6, t7, t8, c6, c7, c8 }) {
  return (
    <div className="">
      {/* <p className="pt-2 text-sm my-5 mt-20 text-white text-center">All Services</p> */}
      <div className="relative opacity-70 top-5">
        <div className="absolute top-96 -left-10 w-1/5 h-[400px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-50 blur-[110px]"></div>
      </div>
      <p className="text-white text-[32px] sm:text-5xl text-center font-medium font-bebas uppercase mt-8 sm:mt-20">{t1} {" "}<span className={`gradient_text`}>
        {t2}</span> </p>
      <div className="top-40">
        <div className="relative opacity-60 top-96">
          <div className="absolute top-96 -right-10 w-1/5 h-[400px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-50 blur-[110px]"></div>
          <div className="absolute top-96 -left-10 w-1/5 h-[300px] bg-[#FE4E59] rounded-full mix-blend-screen filter opacity-40 blur-[120px]"></div>
        </div>
      </div>
      <div className="mx-[16px] grid grid-cols-1 lg:grid-cols-3 gap-4 sm:max-w-7xl sm:mx-auto w-fit my-8 sm:my-16 font-poppins font-light">
        <WobbleCard
          containerClassName="col-span-1 lg:col-span-2 h-full bg-gradient-to-tl from-[#59002114] to-[#FF85B2A3]  max-h-[400px]"
          className=""
        >
          <div className=" ">
            <h2 className="text-left text-lg sm:text-[36px] tracking-[0.03em] font-semibold sm:leading-[54px] text-white min-w-full">
              {t3}</h2>
            <p className="mt-4 text-left max-w-sm text-neutral-200 text-[13px] sm:text-base/6 leading-[23px] ">
              {c3}</p>
          </div>
          <img
            src={img}
            width={350}
            height={350}
            alt=""
            className="hidden sm:block sm:absolute right-0 shadow-md lg:-right-[0%] filter top-24 object-contain rounded-2xl"
          />
        </WobbleCard>

        <WobbleCard containerClassName="col-span-1 max-h-[400px] bg-gradient-to-b from-[#16B9FF33] to-[#B95DEA33]">
          <h2 className="max-w-80 text-left text-balance text-lg sm:text-[36px] font-semibold tracking-[0.03em] sm:leading-[54px] text-white">
            {t4}</h2>
          <p className="mt-4 max-w-[26rem] text-left text-[13px] leading-[23px] text-neutral-200">
            {c4}</p>
        </WobbleCard>
        <WobbleCard containerClassName="col-span-1 sm:min-h-[400px] bg-gradient-to-b from-[#16B9FF33] to-[#B95DEA33]">
          <h2 className="max-w-80 text-left text-balance text-lg sm:text-[36px] font-semibold tracking-[0.03em] sm:leading-[54px] text-white">
            {t5}</h2>
          <p className="mt-4 max-w-[26rem] text-left sm:text-base/6 text-neutral-200 text-[13px] leading-[23px]">
            {c5}</p>
        </WobbleCard>
        <WobbleCard
          containerClassName="col-span-1 lg:col-span-2 h-full bg-gradient-to-b from-[#4D64BCB8] to-[#21178B3D] sm:min-h-[400px]"
          className=""
        >
          <div className="">
            <h2 className="text-left text-lg sm:text-[36px] min-w-full font-semibold tracking-[0.03em] sm:leading-[54px] text-white ">
              {t6}</h2>
            <p className="mt-4 text-left sm:text-base/6 text-neutral-200 max-w-sm text-[13px] leading-[23px]">
              {c6}</p>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2FPlatform%20Page%20banner.svg?alt=media&token=8b94c994-9705-47d8-bd86-9eb5d9d73ab9"
            width={400}
            height={300}
            alt=""
            className="hidden sm:block sm:absolute lg:-right-[1%] filter top-20 object-contain rounded-2xl"
          />
        </WobbleCard>
        <WobbleCard
          containerClassName="col-span-1 lg:col-span-2 h-full bg-gradient-to-b from-[#4D64BCB8] to-[#21178B3D] sm:min-h-[400px]"
          className=""
        >
          <div className="">
            <h2 className="text-left text-lg sm:text-[36px] font-semibold tracking-[0.03em] sm:leading-[54px] text-white">
              {t7}</h2>
            <p className="mt-4 max-w-sm text-left text-neutral-200 text-[13px] sm:text-base/6 leading-[23px]">
              {c7}</p>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/news-f534b.appspot.com/o/testomonials%2FLast.svg?alt=media&token=f7caa6c5-fa96-4ccc-87d0-548bd5e683e3"
            width={450}
            height={300}
            alt=""
            className="hidden sm:block absolute -right-4 lg:-right-[5%] filter top-16 object-contain rounded-2xl"
          />
        </WobbleCard>
        <WobbleCard containerClassName="col-span-1 bg-gradient-to-b from-[#16B9FF33] to-[#B95DEA33]">
          <h2 className="max-w-96 text-left text-balance text-lg sm:text-[36px] font-semibold tracking-[0.03em] sm:leading-[54px] text-white">
            {t8}</h2>
          <p className="mt-4 max-w-[26rem] text-left sm:text-base/6 text-neutral-200 text-[13px] leading-[23px]">
            {c8}</p>
        </WobbleCard>
      </div>
    </div>
  );
}
