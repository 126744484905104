import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { useLocation } from "react-router-dom";

export const TextRevealCard = ({
  text,
  revealText,
  children,
  className,
}) => {
  const [widthPercentage, setWidthPercentage] = useState(0);
  const [direction, setDirection] = useState(1);
  const cardRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setWidthPercentage((prev) => {
        if (prev >= 100 && direction === 1) {
          setDirection(-1);
          return 100;
        } else if (prev <= 0 && direction === -1) {
          setDirection(1);
          return 0;
        }
        return prev + direction * 1;
      });
    }, 40);
    return () => clearInterval(interval);
  }, [direction]);

  const backgroundColor = location.pathname === "/" ? "#11071A" :
    location.pathname === "/business/" ? "#0D192B" : "";

  return (
    <div
      ref={cardRef}
      className={twMerge(
        `rounded-lg sm:p-4 relative overflow-hidden`,
        className
      )}
    >
      {children}
      <div className="h-20 sm:h-40 relative flex items-center overflow-hidden ">
        <motion.div
          style={{
            width: "100%",
          }}
          animate={{
            opacity: widthPercentage > 0 ? 1 : 0,
            clipPath: `inset(0 ${100 - widthPercentage}% 0 0)`,
          }}
          transition={{ duration: 0 }}
          className="absolute will-change-transform"
        >
          <div className="text-[42px] sm:text-8xl sm:px-4 font-bold" style={{ backgroundColor }}>
            <p className="gradient_text">{revealText} </p>
          </div>
        </motion.div>

        <motion.div
          animate={{
            left: `${widthPercentage}%`,
            opacity: widthPercentage > 0 ? 1 : 0,
          }}
          transition={{ duration: 0 }}
          className="h-20 sm:h-40 w-[4px] bg-gradient-to-b from-transparent via-white to-transparent absolute z-40 will-change-transform"
        ></motion.div>

        <div className="">
          <p className="text-[42px] sm:text-8xl sm:px-4 font-bold neonText">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};
