
const AnotherFeatures = ({ src1, src2, src3, src4, src5, t1, t2, t3, t4, t5, c1, c2, c3, c4, c5 }) => {
  return (
    <div className="text-white text-lg">
      <div className="relative z-10">
        {/* <div className="absolute w-[150px] h-[150px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl left-1/4 top-20 sm:left-1/6 sm:top-24"></div>
        <div className="absolute w-[150px] h-[150px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl left-1/3 top-10 sm:left-1/4 sm:top-36"></div> */}

        <div className="flex flex-col items-center py-12 sm:py-16 px-6 sm:px-20">
          {/* <div className="relative z-10 top-32 sm:top-48">
            <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl sm:left-[15vw] sm:top-[20vh]"></div>
            <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl sm:left-[10vw] sm:top-[35vh]"></div>
          </div> */}

          <div className="w-full font-poppins font-extralight">
            <div className="w-full rounded-[56px] bg-gradient-to-r from-[#134FE966] via-[#6300FF66] via-[#D900FF66] to-[#D76D5E66] p-[1px]">
              <div className="flex flex-col sm:flex-row justify-between rounded-[56px] sm:h-[400px] h-auto">
                <div className="w-full sm:w-[60%] flex items-center bg-[#000000f3] rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] justify-center">
                  <div className="backdrop-blur-2xl bg-[#ffffff03] h-full rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] flex justify-center overflow-hidden">
                    <img
                      src={src1}
                      className="h-[300px] w-[489.78px] sm:h-[400px] object-cover"
                      alt="testimonials"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full bg-[#000000d4] rounded-b-[56px] sm:rounded-s-none sm:rounded-e-[56px] p-6 sm:p-20">
                  <p className="font-medium text-[16px] sm:text-[30px] text-white">{t1}</p>
                  <p className="font-light py-4 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] text-justify sm:leading-[2.4rem]">{c1}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-10 font-poppins font-extralight">
            <div className="w-full rounded-[56px] bg-gradient-to-r from-[#134FE966] via-[#6300FF66] via-[#D900FF66] to-[#D76D5E66] p-[1px]">
              <div className="flex flex-col-reverse sm:flex-row justify-between rounded-b-[56px] sm:rounded-e-none sm:rounded-s-[56px] sm:h-[400px] h-auto">
                <div className="flex flex-col w-full rounded-b-[56px] sm:rounded-e-none sm:rounded-s-[56px] bg-[#000000d4] p-6 sm:p-20">
                  <p className="font-medium text-[16px] sm:text-[30px] text-white">{t2}</p>
                  <p className="font-light py-4 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] text-justify sm:leading-[2.4rem]">{c2}</p>
                </div>
                <div className="w-full sm:w-[60%] flex items-center bg-[#000000] rounded-t-[56px] sm:rounded-s-none sm:rounded-e-[56px] justify-center">
                  <div className="backdrop-blur-2xl bg-[#ffffff03] h-full rounded-t-[56px] sm:rounded-s-none  sm:rounded-e-[56px] flex justify-center overflow-hidden">
                    <img
                      src={src2}
                      className="h-[300px] w-[489.78px] sm:h-[400px] object-cover"
                      alt="testimonials"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="relative z-10 top-72 sm:top-96">
            <div className="absolute w-[250px] h-[250px] bg-[#9e00ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl sm:left-[18vw] sm:top-[25vh]"></div>
            <div className="absolute w-[250px] h-[250px] bg-[#0093ff] rounded-full mix-blend-screen filter opacity-40 blur-3xl sm:left-[12vw] sm:top-[40vh]"></div>
          </div> */}

          <div className="w-full mt-8 font-poppins font-extralight">
            <div className="w-full rounded-[56px] bg-gradient-to-r from-[#134FE966] via-[#6300FF66] via-[#D900FF66] to-[#D76D5E66] p-[1px]">
              <div className="flex flex-col sm:flex-row justify-between rounded-3xl sm:h-[400px] h-auto">
                <div className="w-full sm:w-[60%] flex items-center bg-[#000000] rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] justify-center">
                  <div className="backdrop-blur-2xl bg-[#ffffff03] h-full rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] flex justify-center overflow-hidden">
                    <img
                      src={src3}
                      className="h-[300px] w-[489.78px] sm:h-[400px] object-cover"
                      alt="testimonials"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full bg-[#000000d4] rounded-b-[56px] sm:rounded-s-none sm:rounded-e-[56px] p-6 sm:p-20">
                  <p className="font-medium text-[16px] sm:text-[30px]">{t3}</p>
                  <p className="font-light py-4 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] text-justify sm:leading-[2.4rem]">{c3}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-10 font-poppins font-extralight">
            <div className="w-full rounded-[56px] bg-gradient-to-r from-[#134FE966] via-[#6300FF66] via-[#D900FF66] to-[#D76D5E66] p-[1px]">
              <div className="flex flex-col-reverse sm:flex-row justify-between rounded-[56px] sm:h-[400px] h-auto">
                <div className="flex flex-col w-full rounded-b-[56px] sm:rounded-e-none sm:rounded-s-[56px] bg-[#000000d4] p-6 sm:p-20">
                  <p className="font-medium text-[16px] sm:text-[30px]">{t4}</p>
                  <p className="font-light py-4 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] text-justify sm:leading-[2.4rem]">{c4}</p>
                </div>
                <div className="w-full sm:w-[60%] flex items-center bg-[#000000] rounded-t-[56px] sm:rounded-s-none sm:rounded-e-[56px] justify-center">
                  <div className="backdrop-blur-2xl bg-[#ffffff03] h-full rounded-t-[56px] sm:rounded-s-none sm:rounded-e-[56px] flex justify-center overflow-hidden">
                    <img
                      src={src4}
                      className="h-[300px] w-[489.78px] sm:h-[400px] object-cover"
                      alt="testimonials"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-8 font-poppins font-extralight">
            <div className="w-full rounded-[56px] bg-gradient-to-r from-[#134FE966] via-[#6300FF66] via-[#D900FF66] to-[#D76D5E66] p-[1px]">
              <div className="flex flex-col sm:flex-row justify-between rounded-3xl sm:h-[400px] h-auto">
                <div className="w-full sm:w-[60%] flex items-center bg-[#000000] rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] justify-center">
                  <div className="backdrop-blur-2xl bg-[#ffffff03] h-full rounded-t-[56px] sm:rounded-e-none sm:rounded-s-[56px] flex justify-center overflow-hidden">
                    <img
                      src={src5}
                      className="h-[300px] w-[489.78px] sm:h-[400px] object-cover"
                      alt="testimonials"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full bg-[#000000d4] rounded-b-[56px] sm:rounded-s-none sm:rounded-e-[56px] p-6 sm:p-20">
                  <p className="font-medium text-[16px] sm:text-[30px]">{t5}</p>
                  <p className="font-light py-4 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] text-justify sm:leading-[2.4rem]">{c5}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnotherFeatures;
