import TestimonialCarousel from "./TestimonialCarousel"

const Testimonials = ({t1,t2,s1}) => {
    return (
        <div className="p-[16px]">
            <div className="bg-gradient-to-b from-gray-900 via-transparent to-transparent m-[16px] sm:p-0 sm:min-w-5xl h-full sm:max-w-5xl mx-auto flex flex-col items-center rounded-[3rem] py-8 sm:py-10">
                <p className="p-2 text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] ">Testimonials</p>
                <p className="text-white font-normal text-[32px] sm:text-5xl sm:leading-[4rem] px-4 sm:px-10 font-bebas tracking-wide">
                    {t1} </p>
                <p className="text-white font-normal text-[32px] sm:text-5xl sm:leading-[4rem] font-bebas">{t2}{" "}
                    <span className="gradient-text inline-block">
                        {s1}
                    </span>
                </p>
                <TestimonialCarousel />
            </div>
        </div>
    )
}

export default Testimonials


