import Achievements from "./Achievements"
import Footer from "./Footer"
import GetInTouch from "./GetInTouch"
import NavbarDemo from "./NavbarDemo"
import Offering from "./Offering"
import QuotesSlider from "./QuotesSlider"
import Rights from "./Rights"
import { SparklesPreview } from "./SparklesPreview"
import { TimelineDemo } from "./TimelineDemo"

const AboutusPage = () => {
    return (
        <div className="bg-[#11071A] overflow-hidden ">
            <div className="hidden sm:block relative opacity-30">
        <div className="absolute top-20 -left-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-3xl"></div>
        <div className="absolute top-20 -right-36 w-1/5 h-[350px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-3xl"></div>
        <div className="absolute opacity-60 top-60 -left-36 w-1/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
        <div className="absolute opacity-60 top-60 -right-36 w-1/4 h-[650px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
      </div>
            <div className=" flex justify-center ">
            <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-80">
                <NavbarDemo />
            </div>
            </div>
            <SparklesPreview />
            <div className="relative">
                {/* <div className="absolute -top-40 left-24 w-[200px] h-[200px] bg-[#0093ff] rounded-3xl mix-blend-screen filter opacity-50 blur-[120px]"></div> */}
            </div>
            <div className="h-full sm:h-[35rem]">
                <TimelineDemo />
            </div>
            <QuotesSlider />
            <Achievements />
            <div className="relative opacity-40">
                <div className="absolute top-20 left-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[300px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div>
            <Offering/>
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default AboutusPage;
