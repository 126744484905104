import React, { useState, useEffect, useMemo } from "react";
// import img1 from "../assets/logo.png";
import img from "../assets/endless opportunities.svg"

const TypewriterButton = ({bg}) => {
    // const texts = useMemo(() => ["Explore All Art Forms", "Endless Opportunities", "Unleash Your Creativity"], []);
    // const [displayedText, setDisplayedText] = useState("");
    // const [textIndex, setTextIndex] = useState(0);
    // const [isDeleting, setIsDeleting] = useState(false);
    // const [letterIndex, setLetterIndex] = useState(0);
    // const [speed, setSpeed] = useState(100);

    // useEffect(() => {
    //     const handleTyping = () => {
    //         const currentText = texts[textIndex];
    //         if (isDeleting) {
    //             setDisplayedText(currentText.slice(0, letterIndex - 1));
    //             setLetterIndex(letterIndex - 1);
    //             setSpeed(50); 
    //         } else {
    //             setDisplayedText(currentText.slice(0, letterIndex + 1));
    //             setLetterIndex(letterIndex + 1);
    //             setSpeed(100);
    //         }

    //         if (!isDeleting && letterIndex === currentText.length) {
    //             setTimeout(() => setIsDeleting(true), 1000); 
    //         }

    //         if (isDeleting && letterIndex === 0) {
    //             setIsDeleting(false);
    //             setTextIndex((textIndex + 1) % texts.length); 
    //         }
    //     };
    //     // console.log(bg);
        
    //     const timer = setTimeout(handleTyping, speed);
    //     return () => clearTimeout(timer);
    // }, [displayedText, isDeleting, letterIndex, speed, textIndex, texts]);

    return (
        <div className="absolute top-0 inset-0 flex justify-center items-center font-poppins">
            {/* <div className="bg-gradient-to-r from-[#D76D5E66] via-[#D900FF66] to-[#134FE966] p-[1px] rounded-full shadow-xl blur-border">
                <button
                    className={`text-white text-lg font-medium rounded-full px-2 sm:px-4 py-1 sm:py-3 w-36 sm:w-96 h-[50px] sm:h-[80px] flex items-center`}
                    style={{ minWidth: "18rem", minHeight: "2rem", backgroundColor: bg }}
                >
                    <div className="flex items-center">
                        <img src={img1} alt="Logo" className="h-[20px] sm:h-[25px] mr-4" />
                        <span className="bg-gradient-to-r from-blue-700 to-pink-500 text-transparent bg-clip-text sm:text-2xl">
                            {displayedText}
                        </span>
                    </div>
                </button>
            </div> */}
            <div className="bg-opacity-30">
                <img src={img} alt="" className=" w-fit h-fit" />
            </div>
        </div>
    );
};

export default TypewriterButton;
