import img from "../assets/Icons 10.svg"
import img1 from "../assets/Icons 11.svg"
import img2 from "../assets/Icons 12.svg"
import img3 from "../assets/Icons 09.svg"
import img4 from "../assets/Icons 08.svg"
import img5 from "../assets/Icons 07.svg"


const Offering = () => {
    return (
        <div className="flex flex-col items-center text-white mb-10">
            <div className="my-8 sm:my-16">
                <p className="font-poppins p-2 font-light text-center text-[#F5E1FF] opacity-80 text-[14px] sm:text-[18px] ">Core Offerings</p>
                <p className="text-white leading-[4rem] tracking-wide text-[32px] sm:text-5xl font-medium font-bebas text-center"> <span className="gradient_text">DISCOVER</span>{" "} WHAT WE OFFER</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 px-4 md:px-24 gap-6 py-1 font-poppins">
                <div>
                    <div className=" w-fit h-fit rounded-[16px]">
                        <img src={img2} alt="" className="" />
                    </div>
                    <p className=" font-medium py-2">Diverse Talent Pool </p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify ">Hire musicians, artists, and performers across various genres for any occasion. Whether it's a wedding, corporate event, or private party, we have the right talent for you.</p>
                </div>
                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img1} alt="" className="" />
                    </div>
                    <p className=" font-medium py-2">Flexible Booking Options</p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify ">Enjoy a simple and quick booking process for live shows, workshops, and corporate events. Customize your experience to fit your needs and budget.</p>
                </div>
                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img} alt="" className="" />
                    </div>
                    <p className="font-medium py-2">Custom Packages</p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify  ">Get custom-made entertainment solutions designed for festivals, parties, and special occasions. Our packages ensure you have everything needed for a memorable event.</p>
                </div>

                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img3} alt="" className="" />
                    </div>
                    <p className="font-medium py-2">Artist Collaboration </p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify  ">Discover exciting opportunities for artists to work together on creative projects. Collaborate and share ideas to bring your vision to life.</p>
                </div>
                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img4} alt="" className="" />
                    </div>
                    <p className="font-medium py-2">Learning Opportunities</p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify  ">Connect with other artists, find mentors, and discover various learning opportunities to improve your skills and grow your network.</p>
                </div>
                <div>
                    <div className=" w-fit rounded-xl">
                        <img src={img5} alt="" className="" />
                    </div>
                    <p className="font-medium py-2">Seamless Event Management</p>
                    <p className="font-extralight text-[#F5E1FF] opacity-80 text-[14px] text-justify  ">Use our platform to organize and manage your events easily. From planning to execution, we help ensure everything runs smoothly.</p>
                </div>
            </div>
        </div>
    )
}

export default Offering