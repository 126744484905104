import img from "../assets/h1.png"

const ServicesHeader = ({texts}) => {
    return (
        <div>
            <div className="mt-32 text-center flex flex-col items-center">
            <p className="text-white font-normal leading-[4rem] tracking-wide text-[32px] sm:text-5xl font-bebas">
             OUR {" "}
            <span className="gradient_text">
              SERVICES</span> 
              </p>            
            <p className="text-[14px] sm:text-base max-w-4xl p-2 text-center text-white font-extralight font-['Poppins'] ">
            {texts} </p>
            </div>
            <div className="flex flex-col p-4 sm:p-0 items-center mt-6 sm:mt-10">
                <img src={img} alt="" className=""/>
            </div>
            
        </div>
    )
}

export default ServicesHeader