import React from "react";
import { SparklesCore } from "./SparklesCore";

export function SparklesPreview() {
  return (
    <div className="h-[30rem] w-full flex bg-[#11071A] flex-col items-center justify-center overflow-hidden rounded-md">
      <div className=" text-center text-white relative z-20 mt-20">
      <p className="text-white text-[32px] sm:text-5xl font-medium font-bebas leading-[4rem] tracking-wide">ABOUT  <span className="gradient_text">
      TALENTVERSE</span> </p>
      <p className="text-[14px] sm:text-base text-white max-w-4xl text-center p-2 mb-4 font-extralight font-['Poppins']">A platform that empowers Artists, and Creators to showcase their talent, connect with like-minded individuals, fans, opportunities, and potential collaborate.</p>
     
      </div>
      <div className="w-[40rem] h-40 relative">
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

        <SparklesCore
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={1200}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
        <div className="absolute inset-0 w-full h-full bg-[#11071A] [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
      </div>
    </div>
  );
}
