import React, { useState } from 'react';
import RichTextEditor from './RichTextEditor';

export const PostBlog = () => {

    const [formData , setFormData] = useState() ; 

    const handleChange = (e) => {
        console.log(e.value)
        if(e.target){
            setFormData({...formData , [e.target.id]:e.target.value})
        }else{
            // setFormData({ ...formData, [e.id]: e.value });/
        }
    }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-md mt-10">
      <h1 className="text-center my-6 text-3xl font-semibold text-gray-800">Create Your Blog</h1>
      <form className="space-y-6">
        {/* Title Section */}
        <div>
          <label htmlFor="title" className="block text-lg font-medium text-gray-700 mb-2">
            Title
          </label>
          <input
          onChange={handleChange}
            type="text"
            id="title"
            placeholder="Enter blog title"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
          />
        </div>

        {/* Content Section */}
        <div>
          <label htmlFor="content" className="block text-lg font-medium text-gray-700 mb-2">
            Content
          </label>
          <div className="h-[350px]">
            <RichTextEditor onChange={handleChange} id={'content'} height={300}/>
          </div>
        </div>
        {/* Images Section */}
        <div>
          <label htmlFor="images" className="block text-lg font-medium text-gray-700 mb-2">
            Images
          </label>
          <input
          onChange={handleChange}
            type="file"
            id="images"
            accept="image/*"
            className="w-full file:cursor-pointer text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Publish Blog
          </button>
        </div>
      </form>
    </div>
  );
};
