import { FocusCardsDemo } from "./EventCards"
import OneHeading from "./OneHeading"
import Testimonials from "./Testimonials"
import NavbarDemo from "./NavbarDemo"
import HeroSection from "./HeroSection"
import GetInTouch from "./GetInTouch"
import Footer from "./Footer"
import Rights from "./Rights"
import Banner from "./Banner"
import BuisnessFeatures from "./BuisnessFeature"
import BuisnessPreposition from "./BuisnessPreposition"
import FloatingKeysRes from "./FloatingKeysRes"
import { FocusRes } from "./focus-cards-res"
import FloatingKeys from "./FloatingKeys"
import img from "../assets/Othe side.png"

const BuisnessPage = () => {
    return (
        <div className="bg-[#0D192B] overflow-hidden font-['Poppins']">
           <div className="relative ">
            <div className="absolute top-[500px] -left-56 sm:-top-10 sm:-right-36 w-2/5 opacity-60 h-[460px] bg-purple-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
            <div className="absolute top-[500px] -left-56 sm:top-72 sm:-right-36 w-2/5 opacity-60 h-[500px] bg-blue-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
                <div className="absolute sm:-top-10 top-72 -right-0 sm:-right-36 w-2/5 h-[460px] bg-purple-900 rounded-full mix-blend-screen filter opacity-60 blur-[100px] z-30 pointer-events-none"></div>
                <div className="absolute top-48 -right-10 sm:top-72 sm:-right-36 w-2/5 opacity-60 h-[500px] bg-blue-900 rounded-full mix-blend-screen filter blur-[100px] z-30 pointer-events-none"></div>
            </div>
            <div className=" flex justify-center ">
                <div className="fixed top-4 w-full sm:w-[95%] z-50 backdrop-blur-md rounded-2xl bg-opacity-100 ">
                    <NavbarDemo />
                </div>
            </div>
            <HeroSection src={img} t1="Find Artists, Make Events Special" c1="Hire artists, plan engaging events, and discover endless talent at your fingertips with Talentverse." />
            <Banner />
            <OneHeading />
            <div className="hidden sm:block">
                <FloatingKeys bg="#0d192B"/>
            </div>
            <div className="sm:hidden">
                <FloatingKeysRes bg="#0d192B"/>
            </div>
            <BuisnessFeatures />
            {/* <div className="relative opacity-50">
                <div className="absolute top-20 left-0 w-1/5 h-[400px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-20 right-0 w-1/5 h-[400px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 left-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 right-0 w-[300px] h-[300px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div> */}
            <div className={"hidden sm:block"}>
                <FocusCardsDemo />
            </div>
            <div className={"sm:hidden"}>
                <FocusRes />
            </div>
            {/* <div className="relative opacity-50 top-40">
                <div className="absolute top-10 left-0 w-1/5 h-[400px] bg-[#9e00ff] rounded-full mix-blend-soft-light filter opacity-50 blur-[100px]"></div>
                <div className="absolute top-10 right-0 w-1/5 h-[400px] bg-[#9e00ff] rounded-full mix-blend-luminosity filter opacity-50 blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 -left-28 w-[400px] h-[400px] bg-[#0093ff] rounded-full mix-blend-soft-light filter blur-[100px]"></div>
                <div className="absolute opacity-60 top-96 -right-28 w-[400px] h-[400px] bg-[#0093ff] rounded-full mix-blend-luminosity filter blur-[100px]"></div>
            </div> */}
            <BuisnessPreposition />
            <Testimonials t1="REAL EXPERIENCES," t2="REAL" s1="IMPACT" />
            <GetInTouch />
            <Footer />
            <Rights />
        </div>
    )
}

export default BuisnessPage