import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';// In your component or main JavaScript file
const loadPoppinsFont = () => {
  const link = document.createElement("link");
  link.href = "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

// Call this function where needed, e.g., in the root component
loadPoppinsFont();

const loadBebasFont = () => {
  const link = document.createElement("link");
  link.href = "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

// Call this function at the start of your app, e.g., in App.js
loadBebasFont();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
)