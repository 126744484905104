import React, { useState, useEffect } from "react";
import { Quotes } from "./Quotes";

const QuotesSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(false);

      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === Quotes.length - 1 ? 0 : prevIndex + 1
        );
        setAnimate(true);
      }, 800); 
    }, 5000); 

    return () => clearInterval(interval);
  }, []);

  const { quote, author, image } = Quotes[currentIndex];

  return (
    <div className="flex items-center justify-center text-white p-4 my-5">
      <div className="w-full max-w-5xl flex flex-col md:flex-row sm:items-center gap-8">
  
        <div
          className={`${
            animate ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-10"
          } transition-all duration-500 text-left w-full md:w-1/2`}
        >
          <p className="text-[18px] sm:text-2xl md:text-4xl font-light text-center italic leading-relaxed px-4">
          “{quote}”
          </p>
          <p className="mt-4 text-[16px] sm:text-xl text-center font-semibold capitalize px-4">- {author}</p>
          {/* <p className="text-[18px] sm:text-2xl md:text-6xl text-end">&ldquo;</p> */}
        </div>

        <div
          className={`relative ${
            animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-10"
          } transition-all duration-500 w-full md:w-1/2 flex justify-center`}
        >
          <div className="relative w-72 h-72 md:w-80 md:h-80">
            <img
              src={image}
              alt={author}
              className="rounded-lg shadow-lg w-full h-full object-cover"
            />
            <div className="absolute bottom-0 w-full p-4 backdrop-blur-lg rounded-b-lg text-center">
              <p className="font-medium font-poppins">{author}</p>
              <p className="text-sm italic font-poppins">“{quote}”</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesSlider;
