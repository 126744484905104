import React from "react";
import { Timeline } from "./Timeline"

export function TimelineDemo() {
    const data = [
        {
            title: "BRIEF ABOUT TALENTVERSE",
            content: (
                <div>
                    <p className="font-extralight mb-8 md:text-lg text-white font-poppins">
                        At Talentverse, we believe that everyone deserves a chance to showcase their talents, and connect with like-minded individuals. We are designing a platform to bring together a diverse community of Artists, Creators, and Learners, providing them with the tools, and resources they need to thrive. Whether you're a seasoned professional or just starting out, Talentverse is here to support you on your journey.
                        We strive to create a platform that benefits both our users, and artists, placing our community of creators,
                        and users at the heart of our mission.
                    </p>
                </div>
            ),
        },
        {
            title: "WHAT INSPIRED US",
            content: (
                <div>
                    <p className="font-extralight mb-8 sm:text-lg text-white font-poppins">
                        Our journey began with a sample observation: there are countless talented individuals out there who lack the resources and oppurtunities they need to succeed.
                        We saw this as a problem that needed solving, and decided to create a platform that could bring together people from all walks of life, united by their passion for creativity and learning. Our goal is to empower individuals to take control of their own destinities and build meaningful, fulfilling careers doing what they love.
                    </p>
                </div>
            ),
        },
        {
            title: "OUR MISSION",
            content: (
                <div>
                    <p className="font-extralight mb-8 sm:text-lg text-white font-poppins">
                    To be the North star for all forms of talent. Talentverse aims to be a guiding light for everyone to discover, and showcase their talent, and create a world where anyone can pursue their dreams, and achieve their full potential, regardless of their background, or circumstances. We believe that by providing a supportive, inclusive platform for talents and learners, we can help to providing a supportive, inclusive platform for talents and learners, we can help to unlock the power of human potential, and drive positive change in the world.
                    </p>
                   
                </div>
            ),
        },
        {
            title: "OUR VISION",
            content: (
                <div>
                    <p className="font-extralight sm:text-lg mb-8 text-white font-poppins">
                    To be the North star for all forms of talent. Talentverse aims to be a guiding light for everyone to discover, and showcase their talent, and create a world where anyone can pursue their dreams, and achieve their full potential, regardless of their background, or circumstances. We believe that by providing a supportive, inclusive platform for talents and learners, we can help to providing a supportive, inclusive platform for talents and learners, we can help to unlock the power of human potential, and drive positive change in the world.
                    </p>
                   
                </div>
            ),
        },
    ];

    return (
        <div className="w-full bg-[#11071A]">
            <Timeline data={data} />
        </div>
    );
}
